import './App.css';
import {BrowserRouter as Router, Route,Switch} from 'react-router-dom';
import ProtectedRoute from './components/route/ProtectedRoute';
import StaffProtectedRoute from './components/route/StaffProtectedRoute';
import HRProtectedRoute from './components/route/HRProtectedRoute';
import AssistantProtectedRoute from './components/route/AssistantProtectedRoute';
// Admin Dashboard Components
import AdminDashboard from './components/AdminDashboard/Dashboard';
import Admins from './Screens/Admin/Admins';
import Profile from './Screens/Admin/Profile';
import LandingPage from './Screens/LandingPage';
import Login from './Screens/Login';
import PatientsList from './Screens/Admin/PatientsList';
import DoctorsList from './Screens/Admin/DoctorsList';
import DoctorProfile from './Screens/Admin/DoctorProfile';
import AddNewDoctor from './Screens/Admin/AddNewDoctor';
import PatientProfile from './Screens/Admin/PatientProfile';
import EditDoctor from './Screens/Admin/EditDoctor';
import AssignDoctorToPatient from './Screens/Admin/AssignDoctorToPatient';
import RPMDevices from './Screens/Admin/RPMDevices';
import DevicesDetails from './Screens/Admin/DevicesDetails';
import AddRPMDevice from './Screens/Admin/AddRPMDevice';
import UpdateRPMDevice from './Screens/Admin/UpdateRPMDevice';
import PrintReceipt from './Screens/Admin/PrintReceipt';
import AddPatient from './Screens/Admin/AddPatient';
import HRList from './Screens/Admin/HRList';
import HRAdd from './Screens/Admin/HRAdd';
import UpdateHR from './Screens/Admin/UpdateHR';
import HRProfile from './Screens/Admin/HRProfile';
import AssignDrToHr from './Screens/Admin/AssignDrToHr';
import AssignPatientToHr from './Screens/Admin/AssignPatientToHR';
import AdminTimeReport from './Screens/Admin/AdminTimeReport';
import EditPatient from './Screens/Admin/EditPatient';
import InitialMonthReport from './Screens/Admin/InitialMonthReport';
import CompleteInventoryList from './Screens/Admin/CompleteInventoryList';
import AdminResetPassword from './Screens/Admin/AdminResetPassword';
import TelemetaryReport from './Screens/Admin/TelemetaryReport';
import TimeSummaryReport from './Screens/Admin/TimeSummaryReport';
import ManageLogs from './Screens/Admin/ManageLogs';
import EditTelemetaryData from './Screens/Admin/EditTelemetaryData';
import FinancialReportHistory from './Screens/Admin/FinancialReportHistory';
import InactivePatients from './Screens/Admin/InactivePatients';
import CCMPatients from './Screens/Admin/CCMPatients';
import TimeReportCCM from './Screens/Admin/TimeReportCCM';
import FinancialReportHistoryCCM from './Screens/Admin/FinancialReportHistoryCCM';
import DevicesHistory from './Screens/Admin/DevicesHistory';
import BillingReport from './Screens/Admin/BillingReport';
import CCMBillingReport from './Screens/Admin/CCMBillingReport';
import Messages from './Screens/Admin/Messages';
import VerifyAccount from './Screens/VerifyAccount';
import TelemetryMonthlyReadingsLog from './Screens/Admin/TelemetryMonthlyReadingsLog';
import Organizations from './Screens/Admin/Organizations';
import EditOrganization from './Screens/Admin/EditOrganization';
import OrganizationProfile from './Screens/Admin/OrganizationProfile';
import AdminCallReport from './Screens/Admin/AdminCallReport';
import AdminCriticalReadingsDoctorWise from './Screens/Admin/AdminCriticalReadingsDoctorWise';
import ActivePtsReport from './Screens/Admin/ActivePtsReport';
import TimeReportByDate from './Screens/Admin/TimeReportByDate';
import AssignOrganizationToAssistant from './Screens/Admin/AssignOrganizationToAssistant';
import AssignOrganizationToNurse from './Screens/Admin/AssignOrganizationToNurse';
import AvgBPsAdmin from './Screens/Admin/AvgBPsAdmin';
// Staff Screens
import StaffLogin from './Screens/StaffLogin';
import StaffDashboard from './Screens/Staff/StaffDashboard';
import StaffProfile from './Screens/Staff/StaffProfile';
import StaffPatient from './Screens/Staff/StaffPatients';
import StaffPatientProfile from './Screens/Staff/StaffPatientProfile';
import DocCareplanList from './Screens/Staff/DocCareplanList';
import DoctorTimeSummaryReport from './Screens/Staff/DoctorTimeSummaryReport';
import FinancialReportCCM from './Screens/Admin/FinancialReportCCM';
import StaffCCMPatients from './Screens/Staff/StaffCCMPatients';
import DoctorTelemetryReport from './Screens/Staff/DoctorTelemetryReport';
import DoctorTimeReport from './Screens/Staff/DoctorTimeReport';
// HR Screens
import NurseLogin from './Screens/NurseLogin';
import HRDashboard from './Screens/HR/HRDashboard';
import HRPatientsList from './Screens/HR/HRPatientsList';
import HRPatientProfile from './Screens/HR/HRPatientProfile';
import HR from './Screens/HR/HR';
import TimeReport from './Screens/HR/TimeReport';
import HRAddPatient from './Screens/HR/HRAddPatient';
import CareplanDetails from './Screens/HR/CareplanDetails';
import CareplanList from './Screens/HR/CareplanList';
import HRSummaryReport from './Screens/HR/HRSummaryReport'
import NurseCCMPatients from './Screens/HR/NurseCCMPatients';
import MinutesHistory from './Screens/HR/MinutesHistory';
import HRFinancialReportCCM from './Screens/HR/HRFinancialReportCCM';
import TelemetryReportNurse from './Screens/HR/TelemetryReportNurse';
import HRMessages from './Screens/HR/HRMessages';
import HRCriticalReadings from './Screens/HR/HRCriticalReadings';
import HRSettings from './Screens/HR/HRSettings';
import HRCallReport from './Screens/HR/HRCallReport';
// General links
import ForgotPassword from './Screens/ForgotPassword';
import ResetPassword from './Screens/ResetPassword';
// Assistant links
import AssistantLogin from './Screens/AssistantLogin';
import AssistantList from './Screens/Admin/AssistantList';
import AddNewAssistant from './Screens/Admin/AddNewAssistant';
import AssistantDashboard from './Screens/Assistant/AssistantDashboard';
import RPMPatients from './Screens/Assistant/RPMPatients';
import AssistantCCMPatients from './Screens/Assistant/AssistantCCMPatients';
import AssistantTelemetaryReport from './Screens/Assistant/AssistantTelemetryReport';
import AssistantRPMPtProfile from './Screens/Assistant/AssistantRPMPtProfile';
import AssistantMinutesHistory from './Screens/Assistant/AssistantMinutesHistory';
import AssistantTimeReport from './Screens/Assistant/AssistantTimeReport';
import AssistantMessages from './Screens/Assistant/AssistantMessages';
import AddNewOrganizations from './Screens/Admin/AddNewOrganizations';
import AssignOrganizationToDoctor from './Screens/Admin/AssignOrganizationToDoctor';
import Settings from './Screens/Assistant/Settings';
import Page404 from "./components/404Page/404Page";
import HRTimeReportMonthlyLog from './Screens/HR/HRTimeReportMonthlyLog';
import AddPatientAssistant from './Screens/Assistant/AddPatientAssistant';
import AssistantProfile from './Screens/Assistant/AssistantProfile';

function App() {
    return (
        <Router>
            <Switch>

                <Route exact path='/' component={LandingPage}/>

                <Route exact path='/auth/signin/admin' component={Login}/>
                <Route exact path='/auth/signin/hr' component={NurseLogin}/>
                <Route exact path='/auth/signin/clinical_staff' component={AssistantLogin}/>
                <Route exact path='/auth/signin/doctor' component={StaffLogin}/>
                <Route exact path='/auth/forgot' component={ForgotPassword}/>
                <Route exact path="/auth/resetPassword/:token" component={ResetPassword}/>
                <Route exact path="/auth/verify" component={VerifyAccount}/>

                <ProtectedRoute exact path='/patients' isAdmin={true} component={PatientsList}/>
                <ProtectedRoute exact path='/doctors' isAdmin={true} component={DoctorsList}/>
                <ProtectedRoute exact path='/adminDashboard' isAdmin={true} component={AdminDashboard}/>
                <ProtectedRoute exact path='/admins' isAdmin={true} component={Admins}/>
                <ProtectedRoute exact path='/doctorProfile' isAdmin={true} component={DoctorProfile}/>
                <ProtectedRoute exact path='/me' isAdmin={true} component={Profile}/>
                <ProtectedRoute exact path='/adddoctor' isAdmin={true} component={AddNewDoctor}/>
                <ProtectedRoute exact path='/patientprofile' isAdmin={true} component={PatientProfile}/>
                <ProtectedRoute exact path="/Doctor/Edit" isAdmin={true} component={EditDoctor}/>
                <ProtectedRoute exact path='/assigndoctor' isAdmin={true} component={AssignDoctorToPatient}/>
                <ProtectedRoute exact path='/devices' isAdmin={true} component={RPMDevices}/>
                <ProtectedRoute exact path='/devicedetails' isAdmin={true} component={DevicesDetails}/>
                <ProtectedRoute exact path='/device' isAdmin={true} component={AddRPMDevice}/>
                <ProtectedRoute exact path='/printReceipt' isAdmin={true} component={PrintReceipt}/>
                <ProtectedRoute exact path='/updatedevice' isAdmin={true} component={UpdateRPMDevice}/>
                <ProtectedRoute exact path='/addpatient' isAdmin={true} component={AddPatient}/>
                <ProtectedRoute exact path='/hrlist' isAdmin={true} component={HRList}/>
                <ProtectedRoute exact path='/addhr' isAdmin={true} component={HRAdd}/>
                <ProtectedRoute exact path='/updateHR' isAdmin={true} component={UpdateHR}/>
                <ProtectedRoute exact path='/hrProfile' isAdmin={true} component={HRProfile}/>
                <ProtectedRoute exact path='/assignDrToHr' isAdmin={true} component={AssignDrToHr}/>
                <ProtectedRoute exact path='/assignPatientToHr' isAdmin={true} component={AssignPatientToHr}/>
                <ProtectedRoute exact path="/Admin/Report/RPM" isAdmin={true} component={AdminTimeReport}/>
                <ProtectedRoute exact path='/Patients/Edit' isAdmin={true} component={EditPatient}/>
                <ProtectedRoute exact path="/Admin/Report/InitialMonth" isAdmin={true} component={InitialMonthReport}/>
                <ProtectedRoute exact path='/Admin/Inventory/Download' isAdmin={true}
                                component={CompleteInventoryList}/>
                <ProtectedRoute exact path="/Credentials" isAdmin={true} component={AdminResetPassword}/>
                <ProtectedRoute exact path='/report/telemetary' isAdmin={true} component={TelemetaryReport}/>
                <ProtectedRoute exact path='/report/financialReport' isAdmin={true} component={TimeSummaryReport}/>
                <ProtectedRoute exact path='/logs' isAdmin={true} component={ManageLogs}/>
                <ProtectedRoute exact path='/edit/telemetarydata' isAdmin={true} component={EditTelemetaryData}/>
                <ProtectedRoute exact path='/financialReport/history' isAdmin={true}
                                component={FinancialReportHistory}/>
                <ProtectedRoute exact path='/financialReport/CCM' isAdmin={true} component={FinancialReportCCM}/>
                <ProtectedRoute exact path='/Patients/Inactive' isAdmin={true} component={InactivePatients}/>
                <ProtectedRoute exact path='/CCM/Patients' isAdmin={true} component={CCMPatients}/>
                <ProtectedRoute exact path="/Admin/Report/CCM" isAdmin={true} component={TimeReportCCM}/>
                <ProtectedRoute exact path="/financialReport/history/CCM" isAdmin={true}
                                component={FinancialReportHistoryCCM}/>
                <ProtectedRoute exact path="/Devices/History" isAdmin={true} component={DevicesHistory}/>
                <ProtectedRoute exact path="/Assistants" isAdmin={true} component={AssistantList}/>
                <ProtectedRoute exact path="/Add/Assistant" isAdmin={true} component={AddNewAssistant}/>
                <ProtectedRoute exact path="/report/billing" isAdmin={true} component={BillingReport}/>
                <ProtectedRoute exact path="/report/billing/ccm" isAdmin={true} component={CCMBillingReport}/>
                <ProtectedRoute exact path="/messages" isAdmin={true} component={Messages}/>
                <ProtectedRoute exact path="/telemetry-log" isAdmin={true} component={TelemetryMonthlyReadingsLog}/>
                <ProtectedRoute exact path="/organizations" isAdmin={true} component={Organizations}/>
                <ProtectedRoute exact path="/add/organization" isAdmin={true} component={AddNewOrganizations}/>
                <ProtectedRoute exact path="/edit/organization" isAdmin={true} component={EditOrganization}/>
                <ProtectedRoute exact path="/organization" isAdmin={true} component={OrganizationProfile}/>
                <ProtectedRoute exact path="/assign/organization" isAdmin={true}
                                component={AssignOrganizationToDoctor}/>
                <ProtectedRoute exact path="/report/call" isAdmin={true} component={AdminCallReport}/>
                <ProtectedRoute exact path="/critical/telemetryData" isAdmin={true}
                                component={AdminCriticalReadingsDoctorWise}/>
                <ProtectedRoute exact path="/report/activepts" isAdmin={true} component={ActivePtsReport}/>
                <ProtectedRoute exact path="/report/timereportbydate" isAdmin={true} component={TimeReportByDate}/>
                <ProtectedRoute exact path="/assign/organization/clinicalstaff" isAdmin={true}
                                component={AssignOrganizationToAssistant}/>
                <ProtectedRoute exact path="/assign/organization/nurses" isAdmin={true}
                                component={AssignOrganizationToNurse}/>
                <ProtectedRoute exact path="/averageBPs" isAdmin={true} component={AvgBPsAdmin}/>
                {/* Staff Routes */}
                <StaffProtectedRoute exact path="/doctor/dashboard" isStaff={true} component={StaffDashboard}/>
                <StaffProtectedRoute exact path="/staffProfile" isStaff={true} component={StaffProfile}/>
                <StaffProtectedRoute exact path="/doctor/patients" isStaff={true} component={StaffPatient}/>
                <StaffProtectedRoute exact path="/doctor/patient/profile" isStaff={true}
                                     component={StaffPatientProfile}/>
                <StaffProtectedRoute exact path="/doctor/careplan" isStaff={true} component={DocCareplanList}/>
                <StaffProtectedRoute exact path="/doctor/report/financialReport" isStaff={true}
                                     component={DoctorTimeSummaryReport}/>
                <StaffProtectedRoute exact path="/CCM/doctor/patients" isStaff={true} component={StaffCCMPatients}/>
                <StaffProtectedRoute exact path="/doctor/report/telemetry" isStaff={true}
                                     component={DoctorTelemetryReport}/>
                <StaffProtectedRoute exact path="/doctor/report/timereport" isStaff={true}
                                     component={DoctorTimeReport}/>
                {/* HR Routes */}
                <HRProtectedRoute exact path="/Nurse/Dashboard" isHR={true} component={HRDashboard}/>
                <HRProtectedRoute exact path="/Nurse/Patients" isHR={true} component={HRPatientsList}/>
                <HRProtectedRoute exact path="/Nurse/Patient/Profile" isHR={true} component={HRPatientProfile}/>
                <HRProtectedRoute exact path="/hr" isHR={true} component={HR}/>
                <HRProtectedRoute exact path="/Nurse/Report/TimeReport" isHR={true} component={TimeReport}/>
                <HRProtectedRoute exact path="/Nurse/Add_New_Patient" isHR={true} component={HRAddPatient}/>
                <HRProtectedRoute exact path="/Nurse/Careplan" isHR={true} component={CareplanDetails}/>
                <HRProtectedRoute exact path="/Nurse/careplans" isHR={true} component={CareplanList}/>
                <HRProtectedRoute exact path="/Nurse/Report/Financial_Report" isHR={true} component={HRSummaryReport}/>
                <HRProtectedRoute exact path="/CCM/Nurse/Patients" isHR={true} component={NurseCCMPatients}/>
                <HRProtectedRoute exact path="/Nurse/Minutes/History" isHR={true} component={MinutesHistory}/>
                <HRProtectedRoute exact path="/Nurse/Report/Financial_Report/CCM" isHR={true} component={HRFinancialReportCCM}/>
                <HRProtectedRoute exact path="/Nurse/MonthlyTimeReport" isHR={true} component={HRTimeReportMonthlyLog} />
                <HRProtectedRoute exact path="/Nurse/Telemetry/Report" isHR={true} component={TelemetryReportNurse}/>
                <HRProtectedRoute exact path="/Nurse/Messages" isHR={true} component={HRMessages}/>
                <HRProtectedRoute exact path="/Nurse/CriticalReadings" isHR={true} component={HRCriticalReadings}/>
                <HRProtectedRoute exact path="/Nurse/Settings" isHR={true} component={HRSettings}/>
                <HRProtectedRoute exact path="/Nurse/Report/Call" isHR={true} component={HRCallReport} />
                {/* Assistant Routes */}
                <AssistantProtectedRoute exact path="/clinical_staff/dashboard" isAssistant={true}
                                         component={AssistantDashboard}/>
                <AssistantProtectedRoute exact path="/clinical_staff" isAssistant={true}
                                         component={AssistantProfile} />
                <AssistantProtectedRoute exact path="/clinical_staff/patients" isAssistant={true}
                                         component={RPMPatients}/>
                <AssistantProtectedRoute exact path="/clinical_staff/ccm/patients" isAssistant={true}
                                         component={AssistantCCMPatients}/>
                <AssistantProtectedRoute exact path="/clinical_staff/report/telemetry" isAssistant={true}
                                         component={AssistantTelemetaryReport}/>
                <AssistantProtectedRoute exact path="/clinical_staff/patient" isAssistant={true}
                                         component={AssistantRPMPtProfile}/>
                <AssistantProtectedRoute exact path="/clinical_staff/minutes/report" isAssistant={true}
                                         component={AssistantMinutesHistory}/>
                <AssistantProtectedRoute exact path="/clinical_staff/report/timereport" isAssistant={true}
                                         component={AssistantTimeReport}/>
                <AssistantProtectedRoute exact path="/clinical_staff/messages" isAssistant={true}
                                         component={AssistantMessages}/>
                <AssistantProtectedRoute exact path="/clinical_staff/settings" isAssistant={true} component={Settings}/>
                <AssistantProtectedRoute exact path="/assistant/addpatient" isAssistant={true} component={AddPatientAssistant} />
                <Route path="*" component={Page404}/>
            </Switch>
        </Router>
    )
};

export default App;
