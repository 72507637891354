import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { useSelector } from 'react-redux';

const AssistantDoctor = ({ label, compulsory, ...props}) => {
    const [field, meta] = useField(props);
    const { organization } = useSelector(state => state.orgProfile);
    
    return (
            <div>
            <label htmlFor={field.name} className="form-label mt-1">{label} {compulsory === true ? <span style={{color: 'red', fontWeight: 'bold'}}> *</span> : null }</label>
                <select 
                    className={`form-control shadow-none ${meta.touched && meta.error && `is-invalid`}`}
                    {...field} {...props}
                    >
                    <option value="undefined">Select Doctor</option>
                    {organization?.doctors && organization.doctors.map((org) => (
                        <option value={org?.assigned_doctor_id?._id} key={org?._id}>
                            Dr. {org.assigned_doctor_id?.lastname + ', ' + org.assigned_doctor_id?.firstname}
                        </option>
                    ))}    
                </select>
            <ErrorMessage component="div" name={field.name} className="error" />
        </div>
    )
};

export default AssistantDoctor;
