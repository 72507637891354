import {useEffect, useState} from 'react';
import MetaData from '../../layouts/MetaData';
import  HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import {useAlert} from 'react-alert';
import { TIME_SUMMARY_REPORT_RESET } from '../../constants/adminConstants';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import { getTimeSummaryReportforCCMByDrHr } from '../../actions/adminActions';
import { useHistory } from 'react-router-dom';
import { Form, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';


const HRFinancialReportCCM = () => {

  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  
  const [month, setMonth] = useState(moment().subtract(1, "month").format('MM'));
  const [endMonth, setEndMonth] = useState('01');
  const [year, setYear] = useState('2024');
  const [endYear, setEndYear] = useState('2025');

  
  const [filterPatient, setFilterPatient] = useState(false);
  const [reportBy, setReportBy] = useState('hr');

  const { user } = useSelector(state => state.auth);
  const {loading, timeSummaryReport} = useSelector(state => state.summaryReport);

  useEffect(() => {
    
    dispatch({
      type: TIME_SUMMARY_REPORT_RESET
    })

}, [dispatch, month, year]);

  const generateTimeSummaryByHR = () => {
     if(!month){
      alert.error('Please select month');
      return;
    } else if(!year){
      alert.error('Please select year');
      return;
    } else {
      dispatch(getTimeSummaryReportforCCMByDrHr(user?.assigned_doctor_id, user?._id, month, year, reportBy, endMonth, endYear));
    }
  }

  // const resetReport = () => {
  //   dispatch({
  //       type: TIME_SUMMARY_REPORT_RESET
  //   })
  // }  


  return (
    <>
     <MetaData title="Financial Report CCM" />
            <HRSidebar />
            
            <section className="home-section">
                {/* TopBar */}  
                <HRTopBar />

        <>
            <br />
             <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white rounded">        
                <div className="home-content">
                    <div>
                    <div className="row-display">
                        <h5 style={{color: '#00A8A8'}}> 
                            <strong>&nbsp;&nbsp;Financial Report for CCM </strong>
                        </h5>
                      <div>
                        <Link to="/Nurse/Report/Financial_Report" className="link pr-3">RPM Financial Report</Link>  
                        <button className="btn btn-secondary btn-sm" 
                          onClick={() => history.goBack()}><i className='bx bx-arrow-back'></i>
                        </button>
                      </div>
                    </div>


                            <hr />
                            <div className="row">

                            <div className="col-md-3">
                            <label htmlFor="from" className="profile-label">Start Month </label>
                              <select 
                                  label="From" 
                                  name="from" 
                                  className="form-control"
                                  value={month}
                                  onChange={(e) => setMonth(e.target.value)}
                              >
                            <option value="01">January - 26</option>
                            <option value="02">February - 26</option>
                            <option value="03">March - 26</option>
                            <option value="04">April - 26</option>
                            <option value="05">May - 26</option>
                            <option value="06">June - 26</option>
                            <option value="07">July - 26</option>
                            <option value="08">August - 26</option>
                            <option value="09">September - 26</option>
                            <option value="10">October - 26</option>
                            <option value="11">November - 26</option>
                            <option value="12">December - 26</option>
                              </select>
                          </div>

                          <div className="col-md-2">
                            <label className='profile-label pt-1'>Start Year <span style={{color: 'red'}}>*</span></label> 
                                <select 
                                  name="month" 
                                  className="form-control"
                                  value={year} 
                                  onChange={e => setYear(e.target.value)}
                                  >
                                  <option value="2022">2022</option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>
                                </select>              
                            </div>

                          <div className="col-md-3">
                            <label htmlFor="end" className="profile-label">End Month </label>
                                <select  
                                    name="end" 
                                    className="form-control"
                                    value={endMonth}
                                    onChange={(e) => setEndMonth(e.target.value)}
                                >
                              <option value="01">January - 25</option>
                              <option value="02">February - 25</option>
                              <option value="03">March - 25</option>
                              <option value="04">April - 25</option>
                              <option value="05">May - 25</option>
                              <option value="06">June - 25</option>
                              <option value="07">July - 25</option>
                              <option value="08">August - 25</option>
                              <option value="09">September - 25</option>
                              <option value="10">October - 25</option>
                              <option value="11">November - 25</option>
                              <option value="12">December - 25</option>
                            </select>
                            </div>

                            <div className="col-md-2">
                            <label className='profile-label pt-1'>End Year <span style={{color: 'red'}}>*</span></label> 
                                <select 
                                  name="endYear" 
                                  className="form-control"
                                  value={endYear} 
                                  onChange={e => setEndYear(e.target.value)}
                                  >
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>
                                  <option value="2026">2026</option>
                                </select>              
                            </div>
                          
              
                            <div className="col-md-2">
                              <br/> 
                              <button className="edit-pill mt-3" onClick={generateTimeSummaryByHR}>Generate Report</button>
                            </div>
                            </div>

                        </div>
                  </div>

                  

                
                {loading ? <Loader /> : <>

                {timeSummaryReport && timeSummaryReport?.length > 0 ? <>
                        <br/><hr/>
                        
                        <div className="container-fluid row-display">
                          <span>
                            <b>Generated Report Preview</b> <br/>
                            <small style={{color: '#00A8A8', fontWeight: 'bold'}}>Total {timeSummaryReport?.length} records found.</small>
                          </span>

                          <div style={{width: '15%'}}>
                            <Form.Check 
                              checked={filterPatient ? true : false}
                              onChange={() => setFilterPatient(!filterPatient)}
                              type="checkbox"
                              id="filterPts"
                              label="&nbsp;&nbsp;&nbsp;Minutes > 0 ?"
                              style={{fontWeight: 'bold'}}
                            />
                        </div>
                        </div>  

                        <div className="col-md-12">
                          <br />
                        <table className="styled-table">
                            <thead align="center">
                                <tr>
                                <th>Sr.</th>
                                <th>EHR-ID</th>
                                <th>Patient Name</th>
                                <th>D.O.B</th>
                                <th>Total Minutes</th>
                                <th>Your Contribution</th>
                                <th>CPT Codes</th>
                                <th>Month & Year</th>
                                <th>Category</th>
                                </tr>
                              </thead>

                              <tbody>
                                {timeSummaryReport && timeSummaryReport.filter(item => filterPatient ? item.totalMinutes > 0 : item).map((summaryReport, index) => (
                                  <tr key={index}> 
                                    <td><b>{index + 1}</b></td>

                                    <td><b>{summaryReport?.emrId}</b></td>


                                    <td><Link className="link" 
                                        to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: summaryReport?.patientId}}}>
                                          {summaryReport?.patientName}
                                        </Link>
                                    </td>

                                    <td>
                                      <b>{moment(summaryReport?.DOB).format("MM-DD-YYYY")}</b>
                                    </td>

                                    <td>
                                        <b style={{color: summaryReport?.totalMinutes >= 20 ?  'green' : 'red'}}>{summaryReport?.totalMinutes}</b>
                                    </td>

                                    <td><b>{summaryReport?.nurseContributed} mins</b><p>  
                                      <ProgressBar  
                                        animated 
                                        variant='success' 
                                        now={((summaryReport?.nurseContributed / summaryReport?.totalMinutes) * 100).toFixed()} 
                                          />  
                                      </p>
                                    </td>


                                    {/* CPT Codes Payment*/}
                                    <td style={{color: 'green', fontWeight: 'bold'}}>
                                        {summaryReport?.totalMinutes >=20 && summaryReport?.totalMinutes < 40 ? '99490' : 
                                            summaryReport?.totalMinutes >= 40 && summaryReport?.totalMinutes < 60 ? '99490, 99439' : 
                                            summaryReport?.totalMinutes >= 60 && summaryReport?.totalMinutes < 90 ? '99487' : 
                                            summaryReport?.totalMinutes >= 90 && summaryReport?.totalMinutes < 120 ? '99487, 99489' : 
                                            summaryReport?.totalMinutes >= 120 && summaryReport?.totalMinutes < 150 ? '99487, 99489, 99489' : 
                                            summaryReport?.totalMinutes >= 150 ? '99487, 99489, 99489, 99489' :
                                            null  
                                        }
                                    </td>

                                    <td style={{wordWrap: 'break-word'}}>
                                      <b>{month +'- 26 , '+ endMonth + '- 25 -' + endYear}</b></td>

                                    <td style={{fontWeight: 'bold'}}>CCM</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                   </> : null}
                </>}

              </div>
        </>
      </section>
    
    </>
  )
}

export default HRFinancialReportCCM;