    import {useEffect, useState, useRef} from 'react';
    import Sidebar from '../../components/AdminDashboard/Sidebar';
    import TopBar from '../../components/AdminDashboard/TopBar';
    import MetaData from '../../layouts/MetaData';
    import { getDoctorPatients, getHrLists, getDoctors, deleteHRMinutes } from '../../actions/adminActions';
    import { getTimeReport, 
            getTimeReportByHR, 
            getTimeReportByDR,
            getTimeReportByAssistant,
            getTimeReportCCMByAll
    } from '../../actions/HRActions';
    import { useSelector, useDispatch } from 'react-redux';
    import moment from 'moment';
    import { Link, useHistory } from 'react-router-dom';
    import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
    import Loader from '../../layouts/Loader';
    import {useAlert} from 'react-alert';
    import doctorIcon from '../../assets/Images/doctorIcon.png';
    import hrIcon from '../../assets/Images/network.png';
    import patientIcon from '../../assets/Images/network.png';
    import assistantIcon from '../../assets/Images/assistant.png';
    import TimeReportPDF from '../../components/TimeReportPDF';
    import { useReactToPrint } from 'react-to-print';
    import {getAssistants} from '../../actions/assistantActions';
    import patientProfileImg from '../../assets/Images/doctorIcon.png';
    import Select from 'react-select';
    import {Badge} from 'react-bootstrap';


    const AdminTimeReport = () => {

        const dispatch = useDispatch();
        const alert = useAlert();
        const history = useHistory();

        const componentRef = useRef();

        const handlePrint = useReactToPrint({
            pageStyle:"A5",
            documentTitle:  "Time Report",
            content: () => componentRef.current,
        });

        // const { patients} = useSelector(state => state.admin);
        const { doctorpatients } = useSelector(state => state.docPatients);

        const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);
        const { hrs } = useSelector(state => state.hrslist);
        const { doctors } = useSelector(state => state.doctor);
        const {error, message} = useSelector(state => state.common);

        const [patientId, setPatientId] = useState('');
        const [month, setMonth] = useState(moment().format('MM'));
        const [year, setYear] = useState('2024');
        const [hrId, setHRId] = useState('');
        const [drId, setDRId] = useState('');
        const [assistantId, setAssistantId] = useState('');
        const [reportBy, setReportBy] = useState('patient');
        const [reportCategory, setReportCategory] = useState('RPM');
        const { assistants } = useSelector(state => state.assistantsList);
        
    
        useEffect(() =>{
            if(error){
                alert.error(error);
            }

            if(message){
                alert.success(message);
            }

            // if(patientId) {
            //     dispatch(patientProfile(patientId));
            // }

            if(drId){
                dispatch(getDoctorPatients(drId));
            }

            dispatch(getHrLists());
            dispatch(getDoctors(50, 1));
            dispatch(getAssistants());

            dispatch({ type: RESET_TIME_REPORT_DATA });

        }, [dispatch, error, message, drId])

        const submitHandler = () => {
            if(reportBy === 'all'){
                dispatch(getTimeReportCCMByAll(month, year, reportCategory)); 
            } else if(reportBy === 'patient'){
                if(!patientId){
                    alert.error('please select patient');
                    return
                }
                    dispatch(getTimeReport(patientId, month, year,'RPM', 400)); 
                    return; 
                }
                else if(reportBy === 'hr'){
                    if(!hrId){
                        alert.error('please select nurse');
                        return
                    }
                    dispatch(getTimeReportByHR(hrId, month, year, 600)); 
                    return;    
                } else if(reportBy === 'assistant'){
                    if(!assistantId){
                        alert.error('please select assistant');
                        return
                    }
                    dispatch(getTimeReportByAssistant(assistantId, month, year, 500)); 
                    return;    
                } else if(reportBy === 'doctor'){
                    if(!drId){
                        alert.error('please select doctor');
                        return
                    }
                    dispatch(getTimeReportByDR(drId, month, year)); 
                    return;    
                }
            }

        const patientOptions = []
        {doctorpatients && doctorpatients.map((patient) => (
            patientOptions.push({ value: patient?._id, 
                label: [patient?.lastname, patient?.firstname, moment(patient?.DOB).format("MM-DD-YYYY") ].join(" "),
                isBlocked: patient?.block
            })
            ))
        }

        const getPatientProfile = (patient) => {
            setPatientId(patient.value);
        }

        const customStyles = {
            control: (provided) => ({
              ...provided,
              height: '46px', // Adjust the height here as needed
            }),
            option: (provided, state) => ({
                ...provided,
                color: state.data.isBlocked ? 'red' : 'black', // Color based on isBlocked property
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: state.data.isBlocked ? 'red' : 'black', // Color for single value display
            }),
          };

        const resetData = () => {
            dispatch({ type: RESET_TIME_REPORT_DATA });
        }

        const downloadFile = (fileName) => {
            window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
        }

        const deleteHandler = (Id) => {
            var confirmation = window.confirm(`Are you sure you want to delete minutes ?`);
                if(confirmation){
                    dispatch(deleteHRMinutes(Id));
                }
            }

    return <>
        <MetaData title="Time Report"/>
            <Sidebar />    

            <section className="home-section">
            {/* TopBar */}
            <TopBar />

            <div className="shadow-lg background-white m-3">
            <div className='p-3'>
                <div className="home-content">

                <div className="row-display">
                    <h5 className='title'>
                        <i className='bx bx-filter'></i> 
                        <strong>&nbsp;Time Report for RPM </strong>
                    </h5>

                    <div className="row-display">
                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                            <i className='bx bx-arrow-back'></i>
                        </button>
                        &nbsp;&nbsp;
                        <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                        </Link>
                        <Link to="/Admin/Report/CCM" className="link m-2">
                            CCM Time Report
                        </Link>
                    </div>   
                </div>
                <hr />


                <div className="row cardWrapper">
                <div className={`cardButton ${reportBy === "all" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("all")}>
                        <i className="fas fa-list-ol" style={{color: '#02457A', fontSize: '66px'}}></i>
                        <p> By All </p>
                    </div>


                    <div className={`cardButton ${reportBy === "patient" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("patient")}>
                        <img src={patientIcon} alt="" height="60" width="60"/>
                        <p> By Patient </p>
                    </div>
                    
                    <div className={`cardButton ${reportBy === "doctor" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("doctor")}>
                        <img src={doctorIcon} alt="" height="60" width="60"/>
                            <p> By Provider </p>
                    </div>
                    
                    
                    <div className={`cardButton ${reportBy === "hr" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("hr")}>
                        <img src={hrIcon} alt="" height="60" width="60"/>
                        <p> By Nurse </p>
                    </div>

                    <div className={`cardButton ${reportBy === "assistant" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("assistant")}>
                        <img src={assistantIcon} alt="icon" height="60" width="60"/>
                        <p> By Assistant </p>
                    </div>
                </div>
            <br />

                {/* first row */}
                <div className="row">
                    <div className="col-md-5">
                    {reportBy === 'all' ? <>
                        <label className="form-label" htmlFor="from">By All Entities </label>
                        <p><strong>Please select month and year to generate report.</strong></p>
                    </> :     
                    reportBy === 'patient' ? <>
                    <div className="row-display">
                    
                    <div className="col-6">  
                    <label className="form-label" htmlFor="from">Select Doctor </label>
                    <select 
                        className="form-control"
                        value={drId}
                        onChange={(e) => setDRId(e.target.value)}
                        >
                        <option>Select Doctor</option>
                        {doctors && doctors.filter(item => item?.block === false).map((doctor, index) => (
                                <option value={doctor?._id} key={index}>{doctor?.lastname +" , "+ doctor?.firstname} &nbsp;&nbsp;&nbsp; {doctor?.npinumber} </option>
                            ))} 
                    </select>
                    </div>

                    <div className="col-6">            
                    <label className="form-label" htmlFor="from">Select Patient </label>
                        {drId && <Select 
                            className="react-selectbox"
                            options={patientOptions}
                            onChange={getPatientProfile}
                            styles={customStyles}
                        />
                        }
                        
                    </div>
                    </div>               
                    </>
                     : reportBy === 'hr' ? <>
                        
                    <label className="form-label" htmlFor="from">Select Nurse </label>
                    <select 
                        className="form-control"
                        value={hrId}
                        onChange={(e) => setHRId(e.target.value)}
                        >

                        <option>Select Nurse</option>
                        {hrs && hrs.filter(item => item?.block === false).map((hr, index) => (
                                <option value={hr?._id} key={index}>{hr?.lastname +" , "+ hr?.firstname} &nbsp;&nbsp;&nbsp; {hr?.npinumber} </option>
                            ))} 
                    </select>                        
                    </> :
                    reportBy === 'assistant' ? <>

                    <label className="form-label" htmlFor="from">Select Assistant </label>
                    <select 
                        className="form-control"
                        value={assistantId}
                        onChange={(e) => setAssistantId(e.target.value)}
                        >

                        <option>Select Assistant</option>
                        {assistants && assistants.filter(item => item?.block === false).map((assistant, index) => (
                                <option value={assistant?._id} key={index}>{assistant?.lastname +" , "+ assistant?.firstname} &nbsp;&nbsp;&nbsp; {assistant?.npinumber} </option>
                            ))} 
                    </select>
                    </>: <>
                    <label className="form-label" htmlFor="from">Select Provider </label>
                    <select 
                        className="form-control"
                        value={drId}
                        onChange={(e) => setDRId(e.target.value)}
                        >
                        <option>Select Organization</option>
                        {doctors && doctors.filter(item => item?.block === false).map((doctor, index) => (
                                <option value={doctor?._id} key={index}>{doctor?.lastname +" , "+ doctor?.firstname} &nbsp;&nbsp;&nbsp; {doctor?.npinumber} </option>
                            ))} 
                    </select>                        
                    </>}
                </div>

                <div className="col-md-2">
                <label className="form-label" htmlFor="month">Select Month </label>
                    <select 
                      name="month" 
                      className="form-control"
                      value={month} 
                      onChange={e => setMonth(e.target.value)}
                      >
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                </div>

                <div className="col-md-2">
                <label className="form-label" htmlFor="year">Select Year </label>
                    <select 
                      name="year" 
                      className="form-control"
                      value={year} 
                      onChange={e => setYear(e.target.value)}
                      >
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                    </select>
                </div>

                <div className="col-md-2 mt-2">     
                    <label>&nbsp;&nbsp;&nbsp;</label>
                    <button  
                        name="submit" 
                        type="submit" 
                        className="edit-pill mt-4"
                        onClick={submitHandler}
                    >
                        Generate Report
                    </button>
                      </div>
                     </div>
                    </div>
                   </div>  
                    {/* First Row Ends Here */}
                    {loading ? <Loader /> : <>
                    {targets && targets.length > 0 ? <>
                                    
                        <br />
                        <div className="row-display">
                            <div className="col-md-11">
                            <div className="row-display">
                                <h5 style={{color: '#00A8A8'}}>
                                    <i className='bx bxs-watch'></i> <strong>&nbsp;RPM Minutes Added </strong>
                                </h5>

                                <div>
                                    <div className="row">
                                    <div
                                        style={{ display: "none" }} // This make ComponentToPrint show   only while printing
                                    > 
                                    <TimeReportPDF ref={componentRef} 
                                        targets={targets}
                                        interactiveMinutes={totalInteractiveMinutes}
                                        nonInteractiveMinutes={totalNonInteractiveMinutes}
                                        timeSpent={totalTime}
                                        month= {month}
                                        year= {year}
                                        category={reportCategory}
                                    />
                                    </div>
                                    
                                    <button onClick={handlePrint} 
                                        className="edit-pill">
                                        <i className='bx bxs-file-pdf' style={{color:'#F40F02'}}></i> Download Report .PDF
                                    </button>

                                    <button className="edit-pill ml-2" onClick={resetData}>Reset</button>
                                    </div>
                                    </div>


                            </div>     
                            
                            <div className="row-display-secondary">
                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalTime} mins</h2>
                                    <p><b>Total Time Spent</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalInteractiveMinutes} mins</h2>
                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                </div> 
                            </div>
                              </div>        
                            </div>
                            <br />

                            {targets && targets.map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">EHR-ID: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.emrId}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Patient Name: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.lastname +" "+ trgt?.assigned_patient_id?.firstname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Patient DOB: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Account Status: </label> 
                                                <label style={{color: trgt?.assigned_patient_id?.block === true ? 'red' : 'green', fontWeight: 'bold'}}>
                                                    {trgt?.assigned_patient_id?.block === true ? 'Deactivated' : 'Active'}
                                                </label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Start Date: </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format("MM-DD-YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">End Date: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM-DD-YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">S - Time: </label> 
                                                    <label className="report-label ml-2">{trgt?.startTime || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">E - Time: </label> 
                                                    <label className="report-label ml-2">{trgt?.endTime || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                {reportCategory} category</span>
                                                <p>
                                                    {trgt?.encounterType && trgt?.encounterType === 'vm' ? <p>
                                                    <Badge pill bg="primary" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bxs-microphone'></i> &nbsp;&nbsp;Voicemail</Badge>
                                                    </p> : trgt?.encounterType === 'call' ? <Badge pill bg="success" style={{color: 'white', width: '100px', fontSize: '13px'}}> <i className='bx bxs-phone-call'></i> &nbsp;&nbsp; Call</Badge> : trgt?.encounterType === 'review' ? 
                                                    <Badge pill bg="danger" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bx-line-chart'></i> &nbsp;&nbsp; Review</Badge>
                                                    : null 
                                                    }
                                                </p>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <p><button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                                </p></> : null}
                                            </div>
                                        </div>
                                        </div>
    
                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    
                                                    <div className='row-display'>
                                                        <b className='text-white' 
                                                            style={{letterSpacing: '1px'}}> 
                                                            {trgt?.assigned_assistant_id ? 
                                                            trgt?.assigned_assistant_id?.lastname +', '+ trgt?.assigned_assistant_id?.firstname 
                                                            : trgt?.assigned_doctor_id ? 'Dr. '+ trgt?.assigned_doctor_id?.lastname +", "+ trgt?.assigned_doctor_id?.firstname 
                                                            : trgt?.assigned_hr_id?.lastname +", "+ trgt?.assigned_hr_id?.firstname
                                                            }
                                                        </b>

                                                        <button className='btn btn-danger btn-sm'
                                                            onClick={() => deleteHandler(trgt?._id)}>
                                                            Delete
                                                        </button>

                                                    </div>
                                                                        
                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                    <p className="mt-1"><b>{moment(trgt?.createdAt).tz("America/New_York").format("MM-DD-YYYY hh:mm A")}</b></p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>
                                ))}             
                        </> : ''}
                        </>}
                        
                        </div>
                    </section>
                </>
    };

    export default AdminTimeReport;
