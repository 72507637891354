import {useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../layouts/Loader';
import { getTimeReport } from '../../actions/HRActions';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import {Badge} from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import TimeReportPDF from '../../components/TimeReportPDF';




const AdminRPMMinutesComponent = ({patientId}) => {

    const dispatch = useDispatch();
    const [filterMinutes, setFilterMinutes] = useState('all');

    const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);

    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState('2024');
    const [pagination, setPagination] = useState(300);
    
    useEffect(() => {
        dispatch(getTimeReport(patientId, month, year, null, pagination));  
    }, [dispatch]);

    const downloadFile = (fileName) => {
        window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
    }

    const refreshHandler = () => {
        dispatch(getTimeReport(patientId, month, year, null, pagination));  
    }

    const searchHandler = () => {
        dispatch(getTimeReport(patientId, month, year, null, pagination)); 
    }

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        pageStyle:"A5",
        documentTitle: `${targets && targets[0]?.assigned_patient_id?.lastname}, ${targets && targets[0]?.assigned_patient_id?.firstname} RPM Time Report ` + moment().tz("America/New_York").subtract(1, "month").format("MMMM YYYY"),
        content: () => componentRef.current,
    });

        return (
            <div>
                {loading ? <Loader /> : <>
                
                    <div className="coloredRow p-2">
                        <div
                            style={{ display: "none" }} // This make ComponentToPrint show   only while printing
                        >
                        {targets && targets?.length > 0 ? <> 
                        <TimeReportPDF ref={componentRef} 
                            targets={targets}
                            interactiveMinutes={totalInteractiveMinutes}
                            nonInteractiveMinutes={totalNonInteractiveMinutes}
                            timeSpent={totalTime}
                            month= {month}
                            year= {year}
                            category="RPM"
                        />
                        </> : null }
                        </div>

                        {/* print report */}

                        <div className="row" style={{ justifyContent: 'center'}}>
                            <div className='col-md-3'>
                            <label className="form-label" htmlFor="month">Select Month </label>
                                <select 
                                name="month" 
                                className="form-control"
                                value={month} 
                                onChange={e => setMonth(e.target.value)}
                                >
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                                </select>
                            </div>

                            <div className='col-md-3'>
                            <label className="form-label" htmlFor="year">Select Year </label>
                                <select 
                                name="year" 
                                className="form-control"
                                value={year} 
                                onChange={e => setYear(e.target.value)}
                                >
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                </select> 
                            </div>

                            <div className='col-md-3 mt-2'>
                            <label className="form-label">Actions </label><br/>
                                <button className="edit-pill" onClick={() => searchHandler()}>
                                    Search
                                </button>
                                &nbsp;&nbsp;
                                <button className="edit-pill" onClick={() => refreshHandler()}>
                                    Refresh
                                </button> 
                            </div>
                        </div>
                    </div>

                    {targets && targets.length > 0 ? <>
                        <br />
                        {/* <div className="container row-display"> */}
                        <div className="container row-display-secondary">
                                <div className="col-md-4 card p-4 m-1 text-center">
                                    <h2>{totalTime} mins</h2>
                                    <p><b>Total Time Spent in RPM</b></p>
                                </div>

                                <div className="col-md-3 card p-4 m-1 text-center">
                                    <h2>{totalInteractiveMinutes} mins</h2>
                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-3 card p-4 m-1 text-center">
                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                </div>



                                <div className="col-md-2 text-center">
                                    <div className="row-display-column">
                                    <br />    
                                    <div className="row-display">
                                        <span className={`smallFilterBtn ${filterMinutes === 'call' ? 'smallFilterBtnActive' : ''}`}
                                            onClick={() => setFilterMinutes('call')}>
                                                Calls
                                        </span>
                                        &nbsp;&nbsp;

                                        <span className={`smallFilterBtn ${filterMinutes === 'vm' ? 'smallFilterBtnActive' : ''}`}
                                            onClick={() => setFilterMinutes('vm')}>
                                                VMs
                                        </span>
                                        &nbsp;&nbsp;

                                        <span className={`smallFilterBtn ${filterMinutes === 'review' ? 'smallFilterBtnActive' : ''}`}
                                            onClick={() => setFilterMinutes('review')}>
                                                Reviews
                                        </span>
                                    </div>

                                    <div className='row-display'>
                                        <button 
                                            className="edit-pill" 
                                            onClick={() => setFilterMinutes('all')}
                                        ><i className='bx bx-refresh'></i>
                                        </button>

                                        <button 
                                            className="edit-pill" 
                                            onClick={() => handlePrint()}
                                        >
                                            <i className='bx bx-down-arrow-alt'></i> Save
                                        </button>
                                    </div>
                                    </div>
                                </div>
                            </div>                                                           
                            {/* </div>     */}
                            <br />
                                    
                            {targets && targets.filter(item => filterMinutes !== 'all' ? item?.encounterType === filterMinutes : item).map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label"> Name: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.lastname +" "+ trgt?.assigned_patient_id?.firstname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label"> D.O.B: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Start Date </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">End Date: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">Start Time: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.startTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">End Time: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.endTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                RPM category</span>
                                                <p>
                                                    {trgt?.encounterType && trgt?.encounterType === 'vm' ? <p>
                                                    <Badge pill bg="primary" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bxs-microphone'></i> &nbsp;&nbsp;Voicemail</Badge>
                                                    </p> : trgt?.encounterType === 'call' ? <Badge pill bg="success" style={{color: 'white', width: '100px', fontSize: '13px'}}> <i className='bx bxs-phone-call'></i> &nbsp;&nbsp; Call</Badge> : trgt?.encounterType === 'review' ? 
                                                    <Badge pill bg="danger" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bx-line-chart'></i> &nbsp;&nbsp; Review</Badge>
                                                    : null 
                                                    }
                                                </p>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <p><button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                                </p></> : null}
                                            </div>
                                        </div>
                                        </div>
    
                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    
                                                    <div className='row-display'>
                                                        <b className='text-white' 
                                                            style={{letterSpacing: '1px'}}> 
                                                           {trgt?.assigned_assistant_id ? 
                                                            trgt?.assigned_assistant_id?.lastname +', '+ trgt?.assigned_assistant_id?.firstname 
                                                            : trgt?.assigned_doctor_id ? 'Dr. '+ trgt?.assigned_doctor_id?.lastname +", "+ trgt?.assigned_doctor_id?.firstname 
                                                            : trgt?.assigned_hr_id?.lastname +", "+ trgt?.assigned_hr_id?.firstname
                                                            }
                                                        </b>
                                                    </div>
                                                                        
                                                    <p className="mt-1 mr-3">
                                                        {trgt?.conclusion} <br/> 
                                                    <p className="mt-1"><b>{moment(trgt?.createdAt).tz("America/New_York").format("MM-DD-YYYY hh:mm A")}</b></p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>
                                ))}
                    </> : <div className="text-center mt-3 pb-2">
                        <b>RPM minutes not found</b><br /><br />
                        <button className="edit-pill" 
                            onClick={() => refreshHandler()}>
                            Refresh Minutes
                        </button>
                    </div>}
                    </>}
    </div>
)
}

export default AdminRPMMinutesComponent