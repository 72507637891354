import React from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';


export default class MyDocument extends React.PureComponent {
    render() {
         const { healthData } = this.props;
         const {startDate} = this.props;
         const {endDate} = this.props;
         const {filterBy} = this.props

         let filteredReadings = calcTotalReadings();

        function calcTotalReadings() {
            return healthData && healthData.filter(data => data?.deviceId?.deviceType === "bp").reduce((sum, a) =>  
                sum + 1, 0
            )
        }



      return (
          <div>
            <div className="row-display">
                <h1 style={{color: '#00A8A8'}}><b>Remote Monitoring</b></h1>
            </div>

            <br/><br/><br/>        
                <h3 className="text-center">Telemetry Data Report</h3> 
                <h4 className="text-center">Created At : {moment().tz("America/New_York").format("MM/DD/YYYY")}</h4>       
        
            <br /><br />

            <div style={{marginLeft: '30px', marginRight: '30px'}}>
            

            <div className="row-display">
                <div>
                    <h4> EHR-ID :  {healthData[0]?.assigned_patient_id?.emrId} </h4>
                    
                    <h4> Patient Name :  {healthData[0]?.assigned_patient_id?.lastname}, {healthData[0]?.assigned_patient_id?.firstname} </h4>
                    
                    <h4> Gender : {healthData[0]?.assigned_patient_id?.gender === 'male' ? 'Male' : 'Female'} </h4>
                    
                    <h4>D.O.B : {moment(healthData[0]?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</h4>

                    <h4>Provider Name: {healthData[0]?.assigned_doctor_id?.lastname}, {healthData[0]?.assigned_doctor_id?.firstname}</h4>
                </div>

                <div>   
                    <h4> Period :  {moment(startDate).format("MM/DD/YYYY")} to {moment(endDate).format("MM/DD/YYYY")} </h4>
                    <h4> Total Readings Received :  {healthData.filter(item => filterBy !== 'all' ? item?.deviceId?.deviceType === filterBy : item)?.length} </h4>
                    </div>
                </div>

                <br/><br/>
                {healthData.every(element => element.deviceId?.deviceType === "bp") ? <>
                <h2 style={{color: '#00A8A8'}}><b>Summary</b></h2>
                <hr />

                <div className="row-display">
                    <div>
                        <h5>Highest Systolic Recorded:</h5>
                        <h4><span style={{color: 'red'}}> {Math.max(...healthData.map(o => o?.telemetaryData?.sys))}</span> mmHG</h4>
                        <br/>

                        <h5>Lowest Systolic Recorded:</h5>
                        <h4><span style={{color: 'red'}}>{Math.min(...healthData.map(o => o?.telemetaryData?.sys))}</span> mmHG</h4>
                        <br/>
                    </div>

                    <div>
                        <h5>Highest Diastolic Recorded:</h5>
                        <h4><span style={{color: 'red'}}>{Math.max(...healthData.map(o => o?.telemetaryData?.dia))} mmHG </span></h4>
                        <br/>

                        <h5>Lowest Diastolic Recorded:</h5>
                        <h4><span style={{color: 'red'}}>{Math.min(...healthData.map(o => o?.telemetaryData?.dia))} mmHG</span> </h4>
                        <br/>
                    </div>

                    <div>
                        <h5>Average Systolic:</h5>
                        <h3>{healthData && healthData.filter(data => data?.deviceId?.deviceType === "bp").reduce((total, devicedata) =>  
                            (Number(total) + Number(devicedata?.telemetaryData?.sys) / filteredReadings).toFixed(), 0
                            )} mmHG </h3>
                        <br/>

                        <h5>Average Diastolic:</h5>
                            <h3>{healthData && healthData.filter(data => data?.deviceId?.deviceType === "bp").reduce((total, devicedata) =>  
                                (Number(total) + Number(devicedata?.telemetaryData?.dia) / filteredReadings).toFixed(), 0
                                )} mmHG
                            </h3>
                        <br/>
                    </div>
                </div>
 
            <br/><br/>
                </> : null }
                
            <h2 style={{color: '#00A8A8'}}><b>Readings </b></h2>   
                
            <div>
            <Table bordered className="text-center">
                <thead>
                    <tr>
                        <th>Sr.</th>
                        <th>Reading Date & Time</th>
                        <th>Vitals</th>
                        <th>Pulse</th>
                        <th>Device Type</th>
                    </tr>
                </thead>
                
                <tbody>
                {healthData && healthData.filter(item => filterBy !== 'all' ? item?.deviceId?.deviceType === filterBy : item).map((data, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                         
                         {/* date & time */}
                         <td>{data?.telemetaryData?.notification_payload?.reading_type === "pulse_ox" ? <>
                                    {moment(data?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}
                                    </> : <>
                                        {moment(data?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY")}
                                        <p>{moment(data?.telemetaryData?.ts * 1000).tz("America/New_York").format("hh:mm z A")}</p>
                                    </>}
                            </td>
                        
                        
                       {/* vitals */}
                        {data?.deviceId?.deviceType === "bp" ? <td> 
                            {data?.telemetaryData?.sys} / {data?.telemetaryData?.dia} mmHG
                                </td> : data?.deviceId?.deviceType === "weight" ? <td>
                                {(data?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;Kg 
                                <p>{(data?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</p>
                            </td> : data?.deviceId?.deviceType === "sugar" ? <td>
                                    {data?.telemetaryData?.data} &nbsp;
                                    {data?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}
                            </td> : data?.deviceId?.deviceType === "spO2" ? <td>
                                {data?.telemetaryData?.notification_payload?.spo2_pct}
                                <p>Perfusion: {data?.telemetaryData?.notification_payload?.perfusion_index_pct}</p>
                            </td> : <td>---</td> 
                        }
                        
                        {/* Pulse rate */}
                        <td>
                            {data?.telemetaryData?.pul ? data?.telemetaryData?.pul +' bpm' : data?.telemetaryData?.notification_payload?.pulse_bpm ? data?.telemetaryData?.notification_payload?.pulse_bpm + ' bpm' : '---'} 
                        </td>
                        
                        
                    
                        {/* Device Type */}
                        { <td>{data?.deviceId?.deviceType === "bp" ? 
                            <span>B.P</span> : 
                            data?.deviceId?.deviceType === "weight" ? 
                            <span>Weight</span> : 
                            data?.deviceId?.deviceType === "sugar" ? 
                            <span>Glucose</span> :
                            data?.deviceId?.deviceType === "spO2" ? 
                            <span>Spo2</span> : null }  
                        </td> }
                    </tr>
                ))}
                </tbody>
                </Table>

                <br />    
                <div className="signatureDiv">
                {healthData[0]?.assigned_doctor_id?.signature ? <>
                    <img src={`https://www.rpmatrix.com/v1/uploadFiles/${healthData[0]?.assigned_doctor_id?.signature}`} 
                    width='100%' height="95" alt="img" 
                /> </> : null }
                
                <br /><br />
                
                    <p className="text-center"> <b>Dr. {healthData[0]?.assigned_doctor_id?.lastname}, {healthData[0]?.assigned_doctor_id?.firstname} </b></p>  
                    <p className="text-center">{moment().tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</p>
                    <p className="text-center"> <b>( Electronically Verified. ) </b></p>
                </div>
                </div>        
           </div>
                   
         </div>
      )
    }
  }