import {useEffect, useState} from 'react';
import Sidebar from '../../components/Assistant/Sidebar';
import TopBar from '../../components/Assistant/Topbar';
import MetaData from '../../layouts/MetaData';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '../../components/Form/TextField';
import GenderSelectbox from '../../components/Form/GenderSelectbox';
import AssistantDoctor from '../../components/Form/AssistantDoctor';
import { addPatient } from '../../actions/adminActions';
import {useDispatch, useSelector} from 'react-redux';
import { useAlert } from 'react-alert';
import ConsentRoleSelectBox from '../../components/Form/ConsentRoleSelectBox';
import { useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';


const AddPatientAssistant = () => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();
    
    const {message, error } = useSelector(state => state.common);


    useEffect(() => {
        if(error){
            alert.error('Patient Not Added');

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(message) {
            alert.success(message);
            history.push('/clinical_staff/patients');
            
            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

    }, [dispatch, alert, error, message]);


    const validate = Yup.object().shape({
		emrId: Yup.string().required("EHR-ID is required"),
        firstname: Yup.string()
        .required('First Name is required')
        .min(2, 'Should be atleast 2 characters')
        .max(20, 'Should be less than 20 characters'),
		lastname: Yup.string() 
		  .min(2, 'Should be atleast 2 characters')
		  .max(20, 'Should be less than 20 characters')
		  .required('Last Name is Required'),
        email: Yup.string().email(),  
		DOB:Yup.string().required('DOB is required'),
		assigned_doctor_id:Yup.string().required('Doctor is required'),
        phone1: Yup.string(),
        gender: Yup.string().required(),
        zipCode:Yup.string(),
        address:Yup.string(),
        city:Yup.string(),
        state:Yup.string(),
        line2: Yup.string(),
        ssn:Yup.string(),
        insurancecompany: Yup.string(),
	  });

    const submitHandler = (values) => {
        // if(values?.assigned_doctor_id === 'undefined'){
        //     alert.error('Please Select Doctor');
        //     return
        // }

        dispatch(addPatient(values));
    }


  return <>
       <MetaData title="Add New Patient"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />

                <br/>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 rounded" style={{backgroundColor: '#FAFAFA'}}>
                    <div className="home-content">
                    <div className="row-display">
                        <h5 style={{color: '#00A8A8'}}>
                        <i className="fas fa-user"></i>
                            <strong>&nbsp;&nbsp;Add New Patient </strong>
                        </h5>

                        <div className="row-display"> 
                            <button onClick={() => history.goBack()} className="btn btn-secondary btn-sm pt-2"><i className='bx bx-arrow-back'>
                                </i>
                            </button> &nbsp;     
                        </div>
                    </div>
                    <hr />

                    <Alert key="1" variant="warning">
                        1) Fields with <span style={{color: 'red'}}>* </span> are compulsory to add.
                    </Alert>

                    <Alert key="2" variant="danger">
                        2) EHR-ID must be unique. Patient would not be added if Same EHR-ID found.
                    </Alert>

                        <Formik initialValues={{
                             emrId:'',
                             firstname: '',
                             lastname: '',
                             DOB: '',
                             gender: '',
                             assigned_doctor_id: ''
                         }}
                         validationSchema={validate}
                         onSubmit={values => {
                            submitHandler(values)
                        }}>
                           <div>
                           <Form>
                               <div className="row">
                                    {/* EMR ID */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                            label="EHR-ID" 
                                            name="emrId"  
                                            placeholder="EHR ID"
                                            compulsory={true}
                                        />
                                    </div>

                                    {/* First Name */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                            label="First Name" 
                                            name="firstname" 
                                            type="text" 
                                            placeholder="First Name"
                                            compulsory={true}
                                        />
                                    </div>

                                    {/* Last Name */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                            label="Last Name" 
                                            name="lastname" 
                                            type="text" 
                                            placeholder="Last Name"
                                            compulsory={true}
                                        />
                                    </div>

                                    {/* Email */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                            label="Email" 
                                            name="email" 
                                            type="email" 
                                            placeholder="Email"
                                        />
                                    </div>

                                    {/* DOB */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <TextField 
                                            label="DOB" 
                                            name="DOB" 
                                            type="date" 
                                            compulsory={true}
                                        />
                                    </div>

                                    {/* Gender */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <GenderSelectbox 
                                        label="Gender"
                                        name="gender"
                                        compulsory={true}
                                    />
                                    </div>

                                    {/* phone1 */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                                label="Phone 1" 
                                                name="phone1" 
                                                type="text" 
                                                placeholder="Phone Number"
                                        />
                                    </div>


                                    {/* Address */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                                label="Address" 
                                                name="address" 
                                                type="text" 
                                                placeholder="Address"
                                        />
                                    </div>

                                    {/* Address line2 */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                                label="Address Line 2" 
                                                name="line2" 
                                                type="text" 
                                                placeholder="Line 2"
                                        />
                                    </div>

                                    {/* City */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                                label="City" 
                                                name="city" 
                                                type="text" 
                                                placeholder="City"
                                        />
                                    </div>

                                    {/* State */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                            label="State" 
                                            name="state" 
                                            type="text" 
                                            placeholder="State"
                                        />
                                    </div>

                                    {/* Zip Code */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                            label="Zip Code " 
                                            name="zipCode" 
                                            type="text" 
                                            placeholder="Zip Code"
                                        />
                                    </div>

                                    {/* SSN */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                            label="SSN" 
                                            name="ssn" 
                                            type="text" 
                                            placeholder="SSN"
                                        />
                                    </div>

                                    {/* Insurance Company */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <TextField 
                                                label="Insurance Company" 
                                                name="insurancecompany" 
                                                type="text" 
                                                placeholder="Insurance Company"
                                            />
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <ConsentRoleSelectBox
                                            label="Consent Role"
                                            name="patientType"
                                        />
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <AssistantDoctor 
                                            label="Select Doctor"
                                            name="assigned_doctor_id"
                                            compulsory={true}
                                        />
                                        {/* <label className='form-label'>Assigned Doctor</label>
                                        <select className="form-control" name="assigned_doctor_id">    
                                            <option value="undefined">Select Doctor</option>
                                            {organization?.doctors && organization.doctors.map((org) => (
                                                <option value={org?.assigned_doctor_id?._id} key={org?._id}>
                                                    Dr. {org.assigned_doctor_id?.lastname + ', ' + org.assigned_doctor_id?.firstname}
                                                </option>
                                            ))}
                                        </select> */}
                                    </div>
                                </div>
                                

                                 {/* Buttons */}
                                 <br/>
                                 <div className="row mr-3" style={{ float: 'right'}}>
                                    <button className="edit-pill" style={{backgroundColor: 'red', color: '#FFF'}} type="reset">Reset</button> &nbsp;&nbsp;
                                    <button className="edit-pill" type="submit">Add Patient</button>
                                </div>

                                <br/><br/><br/>
                                </Form>
                           </div>  
                        </Formik>   
                    </div>
                </div>

                </section>
  </>
};

export default AddPatientAssistant;
