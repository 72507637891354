import {useEffect, useState} from 'react';
import { removeAssignedDevice} from '../../actions/adminActions';
import { useDispatch, useSelector} from 'react-redux';
import { patientProfile, 
        getPatientTelemetryData, 
        getRemainingReadings,
        getPatientChat,
        calcTelemetryAvg } from '../../actions/adminActions';
import { Link, useHistory } from 'react-router-dom';
import RPMMinutesProgress from '../../components/HR/RPMMinutesProgress';
import CCMMinutesProgress from '../../components/HR/CCMMinutesProgress';
import { useAlert } from 'react-alert';
import PatientCCMConsent from '../../components/Patient/PatientCCMConsent';
import PatientCareplans from '../../components/Patient/PatientCareplans';
import {CCM_CONSENT_RESET} from '../../constants/HRConstants';
import { CCM_CAREPLAN_RESET } from '../../constants/HRConstants';
import PatientRPMConsent from '../../components/Patient/PatientRPMConsent';
import PatientRPMCareplan from '../../components/Patient/PatientRPMCareplan';
import NotFound from '../../components/NotFound';
import PatientEncounters from '../../components/Patient/PatientEncounters';
import { HIDE_ALERT_MESSAGE } from '../../constants/Common';
import PatientOverview from './PatientOverview'; 
import nurseImg from '../../assets/Images/nurse.png';
import userImg from '../../assets/Images/user.png';

const moment = require('moment-timezone');

const PatientInfo = ({patient,patientid, telemetaryReadings, batterySignal}) => {

const dispatch = useDispatch();
const history = useHistory();
const alert = useAlert();
const { user} = useSelector(state => state.auth);
const [readingPerPage, setReadingsPerPage] = useState(15);
const [currentPage, setCurrentPage] = useState(1);
const [sort, sortBy] = useState(-1);

const [minutesCategory, setMinutesCategory] = useState('RPM');
const [month, setMonth] = useState(moment().format('M'));
const [year, setYear] = useState('2024');
const [activeTab, setActiveTab] = useState('vitals');

const [viewChat, setViewChat] = useState(false);

// avgReadings 
const [deviceId, setDeviceId] = useState(patient?.assigned_devices?.length > 0 ? patient?.assigned_devices[0]?.deviceObjectId?._id : null);
const [avgMonth, setAvgMonth] = useState('1');
     const [avgYear, setAvgYear] = useState('2025');

const {error, message} = useSelector(state => state.common);
const { average } = useSelector(state => state.avgCalc);
const {loading : chatLoading, chat} = useSelector(state => state.chatMessages);



const removeAssignDevice = (device, patientid) => {
    var confirmation = window.confirm(`Please confirm to remove device from patient ?`);
        if(confirmation === true){
            dispatch(removeAssignedDevice(device, patientid));
            return;
        }
    }

    useEffect(() => {
    if(message){
        alert.success(message);
        
        dispatch(patientProfile(patientid));
        
        dispatch({
            type: CCM_CONSENT_RESET
        });
        dispatch({
            type: CCM_CAREPLAN_RESET
        });

        dispatch({
            type: HIDE_ALERT_MESSAGE
        })

        dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort));
        dispatch(getRemainingReadings(patientid));
    } 
    
    // dispatch(getPatientChat(patientid));

    if(error){
        alert.error(error);
         
        dispatch({
            type: CCM_CONSENT_RESET
        });
        dispatch({
            type: CCM_CAREPLAN_RESET
        });
    }

    if(deviceId !== null){
        handleAverageCalc();
    }
    
}, [dispatch, message, error])


const handleAverageCalc = () => {
    dispatch(calcTelemetryAvg(patientid, deviceId, avgMonth, avgYear));
  };

return (
    <>  

        <PatientOverview patient={patient}/>
        
        <div> 
            <PatientEncounters 
                patientId={patientid} 
                extraNotes={patient?.extraNotes} 
                user={user}
                isEligible={true}
                patient={patient}
            />
        </div>

        <br/>                
        <div className='mt-3 mb-3' style={{width: '40%'}}>
        <div className='row-display'>
            <button className={`${activeTab === 'vitals' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setActiveTab('vitals')}> Vitals</button>

            <button className={`${activeTab === 'documents' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setActiveTab('documents')}>Documents</button>
        </div>
        </div>
       
       <br/>

    
         <div className="row">           
         <div className='col-md-9' style={{borderRightWidth: '3px', borderRightColor: 'gray', borderRightStyle: 'dotted'}}>
         {activeTab === 'vitals' ? <>
            <h5 className='title'>Recent Vitals</h5>
            
            <div className='row'>
                <div className='col-8'>
                {telemetaryReadings && telemetaryReadings?.length > 0 ? <>
                    <table className="styled-table" >
                            <thead>
                                <tr>
                                    <th width="40px">#</th>
                                    <th>Vitals</th>
                                    <th>Pulse</th>
                                    <th>Device</th>
                                    <th>Date & Time</th>
                                    <th width="40px"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {telemetaryReadings && telemetaryReadings.slice(0,10).map((devicedata, index) => (
                            <tr align="center" key={index} className={devicedata?.notes && devicedata?.notes?.length > 0 ? 'coloredRow' : 'reddishRow'}>
                            
                            {/* index */}
                            <td><b>{index + 1}</b></td>
                            
                            {/* vitals */}
                            {devicedata?.deviceId?.deviceType === "bp" ? <td> 
                                    <b>{devicedata?.telemetaryData?.sys} / {devicedata?.telemetaryData?.dia} mmHG </b><p>
                                        {devicedata?.telemetaryData?.sys >= 139 ? 
                                        <span style={{color: 'red', fontWeight: 'bold'}}>Systolic is High</span> : 
                                        devicedata?.telemetaryData?.sys <= 110 ? <span style={{color: 'red', fontWeight: 'bold'}}> Systolic is Low</span> :
                                        devicedata?.telemetaryData?.dia >= 90 ? 
                                        <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is High</span> : 
                                        devicedata?.telemetaryData?.dia <= 70 ? <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is Low </span> : null }
                                        </p>
                                        </td> : devicedata?.deviceId?.deviceType === "weight" ? <td>
                                        <b>{(devicedata?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;Kg 
                                        <p>{(devicedata?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</p>
                                    </b>
                                </td> : devicedata?.deviceId?.deviceType === "sugar" ? <td>
                                    <b> {devicedata?.telemetaryData?.data} &nbsp;
                                        {devicedata?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}
                                    </b>
                                </td> : devicedata?.deviceId?.deviceType === "spO2" ? <td>
                                    <b>{devicedata?.telemetaryData?.notification_payload?.spo2_pct}
                                    <p>Perfusion: {devicedata?.telemetaryData?.notification_payload?.perfusion_index_pct}</p></b>
                                </td> : <td>---</td> 
                            }

                            {/* Pulse rate */}
                            <td>
                                {/* <b>{devicedata?.telemetaryData?.pul ? devicedata?.telemetaryData?.pul +'bpm' : '---'} </b> */}
                                <b>{devicedata?.telemetaryData?.pul ? devicedata?.telemetaryData?.pul +' bpm' : devicedata?.telemetaryData?.notification_payload?.pulse_bpm ? devicedata?.telemetaryData?.notification_payload?.pulse_bpm + ' bpm' : '---'} </b>

                            </td>
                                        
                            {/* Device Type */}
                            <td><b>{devicedata?.deviceId?.deviceType === "bp" ? 
                                <span className='bpDeviceType'>B.P</span> : 
                                devicedata?.deviceId?.deviceType === "weight" ? 
                                <span className='weightDeviceType'>Weight</span> : 
                                devicedata?.deviceId?.deviceType === "sugar" ? 
                                <span className="glucoseDeviceType">Glucose</span> :
                                devicedata?.deviceId?.deviceType === "spO2" ? 
                                <span className="spo2DeviceType">Spo2</span> : null }</b>    
                            </td>        

                            {/* date & time */}
                            <td>{devicedata?.telemetaryData?.notification_payload?.reading_type === "pulse_ox" ? <>
                                    <b>{moment(devicedata?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</b>
                                    </> : <>
                                        <b>{moment(devicedata?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY")} </b>
                                        <p>{moment(devicedata?.telemetaryData?.ts * 1000).tz("America/New_York").format("hh:mm z A")}</p>
                                    </>}
                            </td>
                            
                            {/* responded or not */}
                            <td>{devicedata?.notes?.length > 0 ? <i className="fas fa-check" style={{color: 'green'}}></i> : 
                                <i className="fas fa-times" style={{color: 'red'}}></i>}
                            </td>
                            </tr>
                            ))}
                            </tbody>
                    </table>
                    </>
                    : <NotFound title="No Record Found."/> 
                }
                </div>


                <div className='col-4'>
                {patient?.assigned_devices?.length > 0 ? <>
                    <h5 className='title text-center'>Average Calculation</h5>

                <div className='row-display'>
                    <select className='form-control' value={deviceId} onChange={e => setDeviceId(e.target.value)}>
                        {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                        <option value={deviceass?.deviceObjectId?._id} key={index}>
                            {deviceass?.deviceObjectId?.deviceType}
                        </option>
                    ))}
                    </select>
                    &nbsp;&nbsp;

                    <select className='form-control' name="month" 
                            value={avgMonth} 
                            onChange={e => setAvgMonth(e.target.value)}
                        >
                            <option value="1">Jan</option>
                            <option value="2">Feb</option>
                            <option value="3">March</option>
                            <option value="4">Apr</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">Aug</option>
                            <option value="9">Sept</option>
                            <option value="10">Oct</option>
                            <option value="11">Nov</option>
                            <option value="12">Dec</option>
                        </select>
                        &nbsp;&nbsp;
                        <select className='form-control' name="year" 
                            value={avgYear} 
                            onChange={e => setAvgYear(e.target.value)}
                        >
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                        </select>
                    &nbsp;&nbsp;
                    <button className='edit-pill' onClick={handleAverageCalc}>
                        <i className="bx bx-search"></i>
                    </button>
                </div>

                <div className='text-center mt-4'>
                    {average?.deviceType === 'bp' ? <>
                    <div className='text-center mt-4'>
                    <i className='bx bx-heart' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Total <b>{average?.totalReadings}</b> Blood Pressure Readings</h5>    
                    <br/>
                    <h2><b>
                        {average?.averageSys} / {average?.averageDia}
                        </b> <small>mmHG</small></h2>
                        </div>
                        <br/>

                        <div className='text-center mt-4'>
                            <i className='bx bx-pulse' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                            <h5 className='text-muted'>Pulse</h5>    
                                <h2><b>
                                {average?.averagePulse}
                                </b> <small>BPM</small></h2>
                        </div>
                    </> 
                    : average?.deviceType === 'spO2' ? <>
                    <i className='bx bxs-droplet' style={{color: '#C20A4D', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Spo2 Readings</h5>
                    <hr />

                    <div className='row-display'>
                        <h5>Average Pulse BPM</h5>
                        <h3>{average?.averagePulseBPM}</h3>
                    </div>
                    <br />
                    <div className='row-display'>
                        <h5>Average Spo2 PCT</h5>
                        <h3>{average?.averageSpo2PCT}</h3>
                    </div> 

                    </> : average?.deviceType === 'weight' ? <>
                    <i className='bx bxs-hourglass-top' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Weight Readings</h5>
                    <hr />

                    <div className='row-display'>
                        <h5>Average Weight</h5>
                        <h3>{average?.averageWeight}</h3>
                    </div>
                    <br />
                    <div className='row-display'>
                        <h5>In KGs</h5>
                        <h3>{(average?.averageWeight / 1000).toFixed(2)}</h3>
                    </div>

                    <br />
                    <div className='row-display'>
                        <h5>In Pounds</h5>
                        <h3>{(average?.averageWeight / 453.6).toFixed(2)}</h3>
                    </div>
                    </> : average?.deviceType === 'sugar' ? <>
                    <i className='bx bxs-droplet' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Sugar Readings</h5>
                    <hr />

                    <div className='row-display'>
                        <h5>Average Sugar</h5>
                        <h3>{average?.averageSugar}</h3>
                    </div>
                    </> : null 
                    }        
                </div>
                <hr/>
                </> :  null }
            

                {/* Devices Assigned */}
                <h5 className='title text-center'>Device(s) Details</h5>

                {patient?.assigned_devices?.length > 0 ?  <>
                    {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                    <div key={index}>
                        <div>
                            <div className="row-display">
                                <div>
                                    <strong className='link'>IMEI & Device ID: </strong> 
                                    <p className="m-0 p-0"><b>{deviceass?.deviceObjectId?.imei || '---'}</b></p>
                                    <p>{deviceass?.deviceObjectId?._id || '---'}</p>
                                </div>

                                <div>
                                <strong className='link'>Device Type: </strong> 
                                    <p><b>{deviceass?.deviceObjectId?.deviceType || '---'}</b></p>
                                </div>

                            </div>

                            <div className="row-display">
                                <div>
                                    <strong className='link'>Assign Date: </strong> 
                                    <p><b>{deviceass?.deviceObjectId?.assignedTime || '---'}</b></p>
                                </div>

                                <div>
                                <strong className='link'>Setup Date</strong>
                                <p><b>{patient?.setupDate || '---'}</b></p>
                                </div>
                            </div>
                            <p>
                            {batterySignal >= 0 && batterySignal <= 10 ? 
                                <small style={{color: 'red', fontWeight: 'bold'}}>Weak</small> : batterySignal > 10 && batterySignal <= 20 ? 
                                <small style={{color: 'orange', fontWeight: 'bold'}}>Medium</small> : batterySignal > 20 ? <small style={{color: 'green', fontWeight: 'bold'}}>Strong</small> : null}
                            </p>

                                <div className='row-display'>
                                <div>  
                                <strong className='link'>RPM Consent : </strong> 
                                    {patient?.rpmconsent === true ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                        Signed</p> : 
                                    <p style={{color: 'red', fontWeight: 'bold'}}>Not Signed</p>}
                                </div>

                                <div>    
                                    <strong className='link'>Initial Month:</strong> 
                                    <p><b>{patient?.initialsetup}</b></p>
                                </div>
                            </div>

                            <div className="mt-3">
                                <button className="edit-pill" style={{width: '49%'}} 
                                onClick={() => {navigator.clipboard.writeText(deviceass?.deviceObjectId?.imei); 
                                    alert.success('IMEI Copied !');
                                   }}> 
                                    <i className='bx bx-edit'></i> Copy IMEI
                                </button>

                                &nbsp;    
                                <Link to={{ pathname:"/devices"}} className="edit-pill" style={{width: '49%'}}>
                                    <i className='bx bx-detail'></i> Go to Device
                                </Link>
                                
                                    
                                <button className="edit-pill mt-2" style={{backgroundColor: '#C82333', color: '#FFF', width: '100%'}} onClick={() => removeAssignDevice(deviceass, patientid)}>
                                    Remove Device
                                </button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    ))}
                    </> : null
                }
     
                </div>        
            </div>
        
       </>  :

       activeTab === 'documents' ? <>
       <div className="row">
        <div className="col-md-6 mt-2">
            <PatientRPMCareplan patientId={patient?._id} isNurse={false}/>
        </div>

        <div className="col-md-6 mt-2">
            <PatientRPMConsent patientId={patient?._id} isNurse={false} />
        </div>

        <div className="col-md-6 mt-2">
            <PatientCareplans patientId={patient?._id} isNurse={false}/>
        </div>

        <div className="col-md-6 mt-2">
            <PatientCCMConsent patientId={patient?._id} isNurse={false}/>
        </div>
        
        </div>
       </> : null}
        </div>

        <div className='col-md-3'>
            <div className="p-2">    
                <h5 className="title mt-2">Targets Count</h5> 
                    <p>
                        <small>For the month of <b>{new Date().toLocaleString('en-us',{month:'long', year:'numeric'})}</b></small>
                    </p>
                

                {patient?.patientType === 'Both' ? <>
                    <select className="form-control" value={minutesCategory} onChange={e => setMinutesCategory(e.target.value)}>
                        <option value="RPM">RPM Category</option>
                        <option value="CCM">CCM Category</option>
                    </select>
                    <br />
                </> : null}                                    
            
                        
                    {patient?.patientType === 'RPM' ? <RPMMinutesProgress patientId={patientid} />
                        : patient?.patientType === 'CCM' ?  <CCMMinutesProgress patientId={patient?._id} />
                        : patient?.patientType === 'Both' ? 
                            <>
                            {minutesCategory === 'RPM' ? <RPMMinutesProgress patientId={patientid} /> 
                            : minutesCategory === 'CCM' ?
                            <CCMMinutesProgress patientId={patient?._id} />
                             : null }
                            </>
                        : null }
                    </div>
            </div> 
        </div>                

    <div className="float">
        <i className='bx bxs-paper-plane' style={{width: '60px', height: '60px', marginTop: '20px'}} 
            onClick={() => setViewChat(true)}>
        </i>
    </div>

    {viewChat === true ? <>
            <div>
            <div className="chat-container">
            <div>
                <div className="row-display p-2">
                    <div className='m-0 p-0'>
                    <h5 className="title">{patient?.lastname +', '+ patient?.firstname}</h5>
                    {chat?.length > 0 ? <>
                        <p className="m-0 p-0">Last conversation: {
                        moment(chat[chat?.length - 1]?.dateAndTime).format("MM-DD-YYYY hh:mm a")
                        }</p>
                    </> : '---'}
                    </div>
                    <button className="btn btn-link" style={{fontSize: '26px'}} onClick={() => setViewChat(false)}>&times;</button>
                </div>
                <hr className="p-0 m-0"/>
            </div>

        <div className="chat-inner">
        {chat && chat.length > 0 ? <>
            {chat && chat.map((message, index) => (
                <div className={message?.sentBy === 'Patient' ? 'chatContainer darker' : 'chatContainer'} key={index}>
                 {message?.sentBy === 'Patient' ? <>
                    <img src={userImg} alt="Avatar" className="right" />
                 </> : <>
                    <img src={nurseImg} alt="Avatar" />
                 </>}   
                {message?.sentBy === 'Patient' ? <>
                    <b style={{color: 'green'}}>{message?.patient_id?.lastname +', '+ message?.patient_id?.firstname}</b>
                </> : <>
                    <b style={{color: 'orange'}}>RN. {message?.nurse_id?.lastname +', '+ message?.nurse_id?.firstname}</b>
                </>}
                <p>{message?.message}</p>
                <span className="time-right">{moment(message?.dateAndTime).format("MM-DD-YYYY hh:mm a")}</span>
                </div>    
            ))}
            </> : <NotFound title="No Conversation yet" />
            }
            </div>

            <div className="chatFooter">
                {/* <div className="row-display">
                    <input type="text" className="chat-form" placeholder="Type your message..." />
                    <button className='edit-pill'>
                        <i className='bx bxs-paper-plane'></i>
                    </button>
                </div> */}
                <div className="text-center">
                    <b style={{color: 'red'}}>Only nurses can send messages</b>
                </div>
            </div>   
        </div>
        </div>
        </> : null }
        
        
    </>
)
}

export default PatientInfo
