import { useState, useEffect } from "react";
import { getPatientEncounters } from '../../actions/adminActions';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import {Modal} from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { addExtraNote, deleteExtraNote } from '../../actions/HRActions';
import NotFoundImg from '../../assets/Images/NotFoundImg.png';
import PatientCCMCareplanManual from '../Patient/PatientCCMCareplanManual';
import PatientCCMCareplanManualView from '../Patient/PatientCCMCareplanManualView';


const PatientEncounters = ({patientId, extraNotes, user, isEligible, patient}) => {
    const dispatch = useDispatch();
    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState('2025');
    const [patientType, setPatientType] = useState('RPM');

    const [noteModalShow, setNoteModalShow] = useState(false);
    const [extraNote, setExtraNote] = useState('');    

    const handleNoteModalShow = () => setNoteModalShow(true);
    const handleNoteModalClose = () => setNoteModalShow(false);

    const [startMonth, setStartMonth] = useState(moment().subtract(1, "month").format('MM'));
    const [endMonth, setEndMonth] = useState(moment().format('MM'));

    const {totalCalls, 
        lastCall, 
        totalVM, 
        lastVM, 
        totalReview, 
        lastReview, 
        callFirstHalf, 
        callSecondHalf, 
        lastCallFirstHalf, 
        lastCallSecondHalf,
        vmFirstHalf,
        vmSecondHalf,
        lastVMFirstHalf,
        lastVMSecondHalf 
    } = useSelector(state => state.ptEncounter);

    useEffect(() => {
        dispatch(getPatientEncounters(patientId, month, year, patientType));
        }, [dispatch, patientType, month, year])

    const submitNoteHandler = () => {
        if(user && extraNote){
            if(user?.role === 'assistant'){
                dispatch(addExtraNote(user._id, extraNote, patientId, user?.role));
             
            } else if (user?.role === 'HrMedical'){
                dispatch(addExtraNote(user._id, extraNote, patientId, user?.role));
            } else if (user?.role === 'admin'){
                dispatch(addExtraNote(user._id, extraNote, patientId, user?.role));
            }
        }
    }

    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth() + 1;

   
    const matchingClinicalFollowUp = patient?.clinicalFollowUp?.some(({ day, month, year, completed }) => {
        return (
            completed === true && (
                (month === parseInt(startMonth, 10) && year === year && day >= 26 && day <= 31) ||
                (month === parseInt(endMonth, 10) && year === year && day >= 1 && day <= 25)
            )
        );
    });
    

    const matchingCareplanFollowUp = patient?.ccmCareplanFollowUp?.some(({ day, month, year, completed }) => {
        return (
            completed === true && (
                (month === parseInt(startMonth, 10) && year === year && day >= 26 && day <= 31) ||
                (month === parseInt(endMonth, 10) && year === year && day >= 1 && day <= 25)
            )
        );
    });

    const handleNoteToggle = (note) => {
        var confirmation = window.confirm(`Are you sure you want to delete a note ?`);
        if(confirmation){
            dispatch(deleteExtraNote(patientId, note?._id, note?.isDeleted));
        }
    }

    return (
    <>
        <br /><br />    	
        <div className="row-display">
        <div className="col-md-6">
            <div className="row-display">
                <div>
                    <h5 className="title"> Patient Encounters</h5>  
                    <p>month of <b>{new Date().toLocaleString('en-us',{month:'long', year:'numeric'})}</b></p>
                </div>

                <div className="row-display">
                <select className="form-control" value={month} onChange={e => setMonth(e.target.value)}>
                    <option key="01" value="01">Jan</option>
                    <option key="02" value="02">Feb</option>
                    <option key="03" value="03">March</option>
                    <option key="04" value="04">April</option>
                    <option key="05" value="05">May</option>
                    <option key="06" value="06">June</option>
                    <option key="07" value="07">July</option>
                    <option key="08" value="08">Aug</option>
                    <option key="09" value="09">Sept</option>
                    <option key="10" value="10">Oct</option>
                    <option key="11" value="11">Nov</option>
                    <option key="12" value="12">Dec</option>
                </select>
                &nbsp;&nbsp;

                <select className="form-control" value={year} onChange={e => setYear(e.target.value)}>
                    <option key="2023" value="2023">2023</option>
                    <option key="2024" value="2024">2024</option>
                    <option key="2025" value="2025">2025</option>
                </select>
                &nbsp;&nbsp;

                <select className="form-control" value={patientType} onChange={e => setPatientType(e.target.value)}>
                    <option key="RPM" value="RPM">RPM</option>
                    <option key="CCM" value="CCM">CCM</option>
                </select>        
                </div>
            </div> 


            <div className="row-display-column">
                {/* Call Encounters */}
                <div className={`row-display p-2 mt-2 ${totalCalls > 0 ? 'coloredRow': 'reddishRow'}`}>
                <i className="bx bx-phone-call encounterIcon"></i>
                    <div className="mt-2">
                        <b className="p-0 m-0">Total - {totalCalls && totalCalls < 10 ? '0'+totalCalls : totalCalls}</b>
                        <p>LC : {totalCalls ? moment(lastCall).format("MM-DD-YYYY") : <b style={{color: 'red'}}>No Call</b>}</p>
                    </div>
                    
                    <div className="mt-2">
                        <b className="p-0 m-0">In First Half - {callFirstHalf && callFirstHalf < 10 ? '0'+callFirstHalf : callFirstHalf}</b>
                        <p>LC : {lastCallFirstHalf ? moment(lastCallFirstHalf).format("MM-DD-YYYY") : <b style={{color: 'red'}}>No Call</b>}</p>
                    </div>

                    <div className="mt-2">
                        <b className="p-0 m-0">In Second Half - {callSecondHalf && callSecondHalf < 10 ? '0'+callSecondHalf : callSecondHalf}</b>
                        <p>LC : {lastCallSecondHalf ? moment(lastCallSecondHalf).format("MM-DD-YYYY") : <b style={{color: 'red'}}>No Call</b>}</p>
                    </div>
                </div>

                {/* Voicemail Encounters */}
                <div className={`row-display p-2 mt-2 ${totalVM > 0 ? 'coloredRow': 'reddishRow'}`}>
                <i className="bx bx-microphone encounterIcon"></i>
                    <div className="mt-2">
                        <b className="p-0 m-0">Total - {totalVM && totalVM < 10 ? '0'+totalVM : totalVM}</b>
                        <p>LC : {lastVM ? moment(lastVM).format("MM-DD-YYYY") : <b style={{color: 'red'}}>No VMs</b>}</p>
                    </div>
                    
                    <div className="mt-2">
                        <b className="p-0 m-0">In First Half - {vmFirstHalf && vmFirstHalf < 10 ? '0'+vmFirstHalf : vmFirstHalf}</b>
                        <p>LC : {lastVMFirstHalf ? moment(lastVMFirstHalf).format("MM-DD-YYYY") : <b style={{color: 'red'}}>No Call</b>}</p>
                    </div>

                    <div className="mt-2">
                        <b className="p-0 m-0">In Second Half - {vmSecondHalf && vmSecondHalf < 10 ? '0'+vmSecondHalf : vmSecondHalf}</b>
                        <p>LC : {lastVMSecondHalf ? moment(lastVMSecondHalf).format("MM-DD-YYYY") : <b style={{color: 'red'}}>No Call</b>}</p>
                    </div>
                </div>

                {/* Reviews Encounters */}
                <div className={`row-display p-2 mt-2 ${totalReview > 0 ? 'coloredRow': 'reddishRow'}`}>
                <i className="bx bx-line-chart encounterIcon"></i>
                    <div className="mt-2">
                        <b className="p-0 m-0">Total - {totalReview && totalReview < 10 ? '0'+totalReview : totalReview}</b>
                        <p>LC : {lastReview ? moment(lastReview).format("MM-DD-YYYY") : <b style={{color: 'red'}}>No VMs</b>}</p>
                    </div>
                    
                    <div className="mt-2">
                        <b className="p-0 m-0">In First Half - {vmFirstHalf && vmFirstHalf < 10 ? '0'+vmFirstHalf : vmFirstHalf}</b>
                        <p>-------</p>
                    </div>

                    <div className="mt-2">
                        <b className="p-0 m-0">In Second Half - {vmSecondHalf && vmSecondHalf < 10 ? '0'+vmSecondHalf : vmSecondHalf}</b>
                        <p>-------</p>
                    </div>
                </div>
            </div>   
        </div>

        <div className="col-md-3">
            <h5 className="title mb-3"> Complete Call FollowUp Status</h5>
            <p className="text-muted">Span of 26-12-2024 to 25-01-2025</p>
            
            {/* {currentDay >= 26 && currentDay <= 31 ? <>
                <p className="text-muted">Span of {currentMonth + ' -' + '26- ' + year + ' to ' + (currentMonth + 1) + '- 25 -' + year}</p>
            </> : <>
                <p className="text-muted">Span of {(currentMonth - 1) +' -'+ '26- ' + year + ' to ' + (currentMonth) + '- 25 -' + year}</p>
            </>}     */}
                

            <div className="row-display mt-2">
                <b>1 ) Clinical FollowUp</b>
                {matchingClinicalFollowUp ? 
                <span><i className="fa fa-check" style={{color: 'green'}}></i></span> : <span>
                    <span><i className="fa fa-times" style={{color: 'red'}}></i></span>
                </span>    
                }
            </div>
            {matchingClinicalFollowUp ? 
                <span>Added Date: {patient?.clinicalFollowUp[patient.clinicalFollowUp.length - 1]?.month +'-'+ patient?.clinicalFollowUp[patient.clinicalFollowUp.length - 1]?.day  +'-'+ patient?.clinicalFollowUp[patient.clinicalFollowUp.length - 1]?.year}
                </span> : null   
                }

            <div className="row-display mt-2">
                <b>2 ) CCM Careplan FollowUp</b>
                {matchingCareplanFollowUp ? 
                <span><i className="fa fa-check" style={{color: 'green'}}></i></span> : <span>
                    <span><i className="fa fa-times" style={{color: 'red'}}></i></span>
                </span>}
            </div>
            {matchingCareplanFollowUp ? 
                <span>Added Date: {patient?.clinicalFollowUp[patient.clinicalFollowUp.length - 1]?.month +'-'+ patient?.clinicalFollowUp[patient.clinicalFollowUp.length - 1]?.day  +'-'+ patient?.clinicalFollowUp[0]?.year}
                </span> : null   
            }
            <hr />
            <h5 className="title mb-3"> CCM Careplan</h5>

            <div className="text-center">
            {patient?.ccmCareplanManualAdded === false && patient?.sickness?.length > 0  ? 
                        <PatientCCMCareplanManual 
                        patient={patient} 
                    />
                    : patient?.sickness?.length > 0 && <b style={{color: 'green'}}>
                        <i className='bx bx-check'></i> CCM Careplan Added
                      </b>
                }
                    
                {patient?.ccmCareplanManualAdded === true ? <PatientCCMCareplanManualView
                    patient={patient} 
                /> : null}
            </div>
        </div>

        {/* Extra Note section */}
        <div className="col-md-3 extraNoteCard">
                <div className="row-display">
                 <b>Extra Note(s) : </b>
                 
                 {isEligible === true && <button className="edit-pill" onClick={handleNoteModalShow}>
                    <i className='fa fa-plus'></i></button>}
                </div>
                <hr />
                {extraNotes && extraNotes.length > 0 ?
                <>
                <ul>
                {extraNotes.map((note) => (
                    <li key={`${note?._id}-${note?.createdAt}`}>
                    {note && !note?.isDeleted ? (
                        <div>
                        {note?.note}
                        <div className="row-display">
                            <p>
                            <b>
                                {note?.assigned_hr_id
                                ? `${note?.assigned_hr_id?.lastname}, ${note?.assigned_hr_id?.firstname}`
                                : note?.assigned_assistant_id
                                ? `${note?.assigned_assistant_id?.firstname}, ${note?.assigned_assistant_id?.lastname}`
                                : note?.adminId?.name}
                            </b>
                            </p>
                            <p>
                            <i>{moment(note?.createdAt).format("MM-DD-YYYY hh:mm z A")}</i>
                            </p>
                            {isEligible ? 
                                <button onClick={() => handleNoteToggle(note)} className="chronicSickness m-1">
                                    <i className="bx bx-trash"></i>
                                </button>
                            : null}
                            
                        </div>
                        </div>
                    ) : (
                        <strike>
                        {note?.note}
                        <div className="row-display">
                            <strike>
                                {note?.assigned_hr_id
                                ? `${note?.assigned_hr_id?.lastname}, ${note?.assigned_hr_id?.firstname}`
                                : note?.assigned_assistant_id
                                ? `${note?.assigned_assistant_id?.firstname}, ${note?.assigned_assistant_id?.lastname}`
                                : note?.adminId?.name}
                            </strike>
                            
                            <strike>{moment(note?.createdAt).format("MM-DD-YYYY hh:mm z A")}</strike>
                            
                            {isEligible ? 
                                <button onClick={() => handleNoteToggle(note)} className="acuteSickness m-1">
                                    <i className="bx bx-refresh"></i>
                                </button>
                            : null}
                        </div>
                        </strike>
                    )}
                    <hr />
                    </li>
                ))}
                </ul>
                </>   
                : 
                <div className="text-center">
                    <img src={NotFoundImg} width="150px" height="150px" />
                </div>  }
            </div>     
        {/* Extra Note section */}
        </div>

       
            


        {/* Add Special Instruction Modal */}
        <Modal show={noteModalShow} onHide={handleNoteModalClose}>
            <Modal.Header >
                <h5 className="title">Add New Note</h5> 
                <button className='edit-pill'onClick={handleNoteModalClose}>
                    <i className='bx bx-x'></i></button>
            </Modal.Header>

                <Modal.Body>
                    <Formik initialValues={{
                        note: ''

                    }}
                    onSubmit={values => {
                        submitNoteHandler(values)
                    }}
                    >
                        <div>
                            <Form>                            
                            <label htmlFor="note" className="form-label mt-3">Note</label>
                                <textarea 
                                    name="note"
                                    className="form-control"
                                    rows="4"	
                                    value={extraNote} 
                                    onChange={(e) => setExtraNote(e.target.value)}
                                    placeholder="Type note here .... "
                                />
                            <br/>

                            <div className="row-class" style={{justifyContent: 'space-between'}}>
                                {extraNote !== '' ? <button className="edit-pill ml-3" type="submit"> Add Note</button> : null}
                            </div>
                            </Form>
                        </div>
                    </Formik>   
                </Modal.Body>
            </Modal>
            {/* Extra note modeal ends here*/}
    </>
  )
}

export default PatientEncounters