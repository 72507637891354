import {useState, useEffect} from 'react'
import MetaData from '../../layouts/MetaData';
import  HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import { useDispatch, useSelector } from 'react-redux';
import { getHRStats, getTimeReportByHR, getTimeReportCCMByHR } from '../../actions/HRActions';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import moment from 'moment';
import PatientsWithCriticalDataDoctor from '../../components/Staff/PatientsWithCriticalDataDoctor';
import HRRecentReadings from '../../Screens/HR/HRRecentReadings';
import Loader from '../../layouts/Loader';


const HRDashboard = () => {
    const [month, setMonth] = useState(moment().format('MM')); //moment().month()
    const [year, setYear] = useState(moment().year());
    const [startYear, setStartYear] = useState(moment().year() - 1);
    const [endYear, setEndYear] = useState(moment().year());
    const { user, isAuthenticated} = useSelector(state => state.auth);
    const { totalPatients, activePatients, InactivePatients, orgsAssigned } = useSelector(state => state.hrStats);
    const [selectedDr, setSelectedDr] = useState(localStorage.getItem('doctorId'));
    
    const [startMonth, setStartMonth] = useState(moment().subtract(1, "month").format('MM'));
    const [endMonth, setEndMonth] = useState(moment().format('MM'));

    // const [startMonth, setStartMonth] = useState(moment().format('MM'));
    // const [endMonth, setEndMonth] = useState(moment().add(1, "month").format('MM'));

    const dispatch = useDispatch();
    let id = user?._id;

    let nurseDrs = localStorage.getItem('nurseSelectedDrs'); 
    let countLength = JSON.parse(nurseDrs);

    const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes, count} = useSelector(state => state.target);

    const [reportCategory, setReportCategory] = useState('RPM');
    const [pagination, setPagination] = useState(15);

    useEffect(() => {

    dispatch(getHRStats(id, nurseDrs));

    if(selectedDr && selectedDr !== 'default'){    
        if(reportCategory === 'RPM'){
            dispatch(getTimeReportByHR(id, month, year, pagination)); 
        } else if(reportCategory === 'CCM'){
            dispatch(getTimeReportCCMByHR(id, startMonth, endMonth, startYear, endYear, pagination));  
        }
    }

    }, [dispatch, isAuthenticated, reportCategory, selectedDr, pagination])


    const updateTargetsPagination = () => {
        setPagination(pagination + 1);
    }

    const refreshHandler = () => {
        if(reportCategory === 'RPM'){
            dispatch(getTimeReportByHR(id, month, year, pagination)); 
        } else if(reportCategory === 'CCM'){
            dispatch(getTimeReportCCMByHR(id, startMonth, endMonth, startYear, endYear, pagination));  
        }
    }
    

    return (
        <>
            <MetaData title="Nurse Dashboard" />
            <HRSidebar />
            
            <section className="home-section background-white">
                <HRTopBar />

                <div className="row-display">
                    <div className="col-md-3 mt-2">
                        <div className="card dashboardStatsCard greenCardDashboardStatsCard">
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <h1>{totalPatients && totalPatients < 10 ? '0'+totalPatients : totalPatients }</h1>
                                <div>
                                <b>Total Patients</b><br/>
                                <small>Updated Just now</small>
                            </div>
                            </div>   
                        </div>
                    </div>

                    <div className="col-md-3 dashboardStatsCard">
                    <div className="card dashboardStatsCard blueCardDashboardStatsCard">
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <h1>{activePatients && activePatients<10 ? '0'+activePatients : activePatients}</h1>
                                <div>
                                <b>Active Patients</b><br/>
                            
                            <small> Updated just now</small>
                            </div>
                            </div>   
                    </div>
                    </div>

                    <div className="col-md-3  dashboardStatsCard">
                    <div className="card dashboardStatsCard redCardDashboardStatsCard">
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <h1>{InactivePatients && InactivePatients<10 ? '0'+InactivePatients : InactivePatients}</h1>
                                <div>
                            <b>Deactivated Accounts</b><br/>
                            <small> Updated just now</small>
                            </div>
                            </div>   
                        </div>
                    </div>

                    <div className="col-md-3 dashboardStatsCard">
                    <div className="card dashboardStatsCar greenCardDashboardStatsCard">
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            
                            <div>
                            <h5>Assigned Organizations</h5>
                            {orgsAssigned && orgsAssigned?.length && orgsAssigned.map((org, index) => (
                                <b key={index}>{org?.name} <br/></b>
                            )) 
                            }
                            </div>
                            </div>   
                        </div>
                    </div>
                </div>


                <div className="container-fluid">    
                        
                <div className="col-md-12">
                    {countLength && countLength?.length > 0 ? <>
                        {user.consentRole === 'RPM' || user.consentRole === 'Both' ? <>
                            <PatientsWithCriticalDataDoctor isNurse={true} />
                        </> : null}
                    </> : null}
                </div>


                {selectedDr && selectedDr !== 'default' ? <>
                
                {/* Targeted Minutes */}
                <div className="row-display">
                {loading ? <Loader /> : <>
                <div className="col-md-8">
                {/* Minutes History  */}
                <div className="shadow-lg p-3 mb-5 background-white recent-readings-card">        
                    <div className="container-fluid">
                    <div className="row-display">
                
                <div>    
                    <h5 className="title">Your {reportCategory} Minutes History</h5>
                    <p>Showing <b>{targets?.length}</b> results out of <b>{count}</b></p>
                </div>

                <div>
                    <div className="row-display">
                        <select 
                            value={reportCategory} 
                            onChange={e => setReportCategory(e.target.value)}
                            className="form-control"
                        >
                            <option value="RPM">RPM</option>
                            <option value="CCM">CCM</option>
                        </select>
                        &nbsp;&nbsp;
                        <Link to="/Nurse/Minutes/History" className="edit-pill m-2">History</Link>
                        <button className="edit-pill m-2" onClick={() => refreshHandler()}>
                            <i className="bx bx-refresh"></i>
                        </button>

                    </div>
                </div>
                </div>
                <hr className="p-0 m-0"/>
                    <div className="text-center">
                        {reportCategory === 'RPM' ?
                        <h5 className='title mt-2'>Month & Year : {month +' - '+ year}</h5> :
                        <h5 className='title mt-2'>Time Duration : {'26' +'-'+ startMonth +'-'+ startYear +' to '+ '25-'+ endMonth + '-'+ endYear}</h5>
                        }
                    </div>

                    <div className="row-display">
                    <div className="card dashboardStatsCard blueCardDashboardStatsCard">
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <h1>{totalTime}</h1>
                        <div className="ml-4">
                            Total Minutes Added<br/>
                        <b><small>In this month</small></b>
                        </div>
                        </div>   
                    </div>

                    <div className="card dashboardStatsCard greenCardDashboardStatsCard">
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <h1>{totalInteractiveMinutes}</h1>
                        <div className="ml-4">
                            Interactive Minutes<br/>
                        <b> <small>In this month</small></b>
                        </div>
                        </div>   
                    </div>

                    <div className="card dashboardStatsCard redCardDashboardStatsCard">
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <h1>{totalNonInteractiveMinutes}</h1>
                        <div className="ml-4">
                            Non-Interactive Minutes<br/>
                        <b><small>In this month</small></b>
                        </div>
                        </div>   
                    </div>
                    </div>            
                    <br />

                    {targets && targets.length > 0 ? <>
                                
                        {targets && targets.map((trgt, index) => ( 
                        <div key={index}>
                        
                        <div>
                            <div className='row-display'>
                                
                                <div>
                                    <b>RN. {trgt?.assigned_hr_id ? trgt?.assigned_hr_id?.lastname +" , "+ trgt?.assigned_hr_id?.firstname : trgt?.assigned_assistant_id?.lastname + " , " + trgt?.assigned_assistant_id?.firstname}</b><br/>
                                    <hr className="m-0 p-0"/>
                                    <b>EHR-ID : </b> {trgt?.assigned_patient_id?.emrId}<br />
                                    <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: trgt?.assigned_patient_id?._id}}} className="link"> {trgt?.assigned_patient_id?.lastname +" , "+ trgt?.assigned_patient_id?.firstname} </Link>
                                    <br/><b>D.O.B :</b> {moment(trgt?.assigned_patient_id?.DOB).format("MM-DD-YYYY")}<br />
                                </div>
                                
                                <div className="ml-5">
                                    <b>Minutes Added</b><br/>
                                    <h5>{trgt?.timeSpentInMinutes} min(s) &nbsp;&nbsp; {trgt?.isCCM === true ? 'CCM' : 'RPM'}</h5>
                                </div>

                                

                                <div className='ml-5'>
                                    <b>Date & Time</b><br/>
                                    <p>{moment(trgt?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                </div>

                                <div className='ml-3'>
                                    {trgt?.encounterType && trgt?.encounterType === 'vm' ? <p>
                                    <Badge pill bg="primary" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bxs-microphone'></i> &nbsp;&nbsp;Voicemail</Badge>
                                    </p> : trgt?.encounterType === 'call' ? <Badge pill bg="success" style={{color: 'white', width: '100px', fontSize: '13px'}}> <i className='bx bxs-phone-call'></i> &nbsp;&nbsp; Call</Badge> : trgt?.encounterType === 'review' ? 
                                    <Badge pill bg="danger" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bx-line-chart'></i> &nbsp;&nbsp; Review</Badge>
                                    : null 
                                    }
                                </div>
                            </div>   
                        </div>  
                        <br />                
                        <p className="profile-label m-0 p-0">Conclusion:</p>            
                        <p style={{textAlign: "justify"}}>{trgt?.conclusion}</p>
                        <hr />

                        <br/>
                        </div>

                    ))}
                </> : <div className="text-center" style={{marginTop: '70px'}}>
                    <i className="fas fa-search-minus" style={{fontSize: '60px', color: '#00A8A8'}}></i>
                    <p className="mt-2">No Minutes added in <span style={{ fontWeight: 'bold' }}>
                        {moment().format('MMMM')}</span> 
                        &nbsp; in  <span style={{fontWeight: 'bold'}}>{reportCategory}</span> category.</p>
                    </div>}
                    </div>

                    {targets?.length < count && <>
                            <div className="text-center">
                                <button className="edit-pill" onClick={() => updateTargetsPagination()}>Load More Results</button>
                            </div>
                        </> }
                    </div>
                </div>
                </>}    
                
                {/* Recent Readings */}
                <div className="col-md-4">
                    {countLength?.length > 0 ? <>
                        <HRRecentReadings />        
                    </> : null}
                </div>
                </div>   
                
                </> : null}                                                         
            </div>                
        </section>
    </>
    )
}

export default HRDashboard;
