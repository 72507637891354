import axios from 'axios';
import { Prod01 } from '../constants/ActionType';

import {
    REQUEST_ASSISTANTS,
    REQUEST_ASSISTANTS_SUCCESS,
    REQUEST_ASSISTANTS_FAIL,
    ASSISTANT_STATS_SUCCESS,
    ASSISTANT_STATS_FAIL,
    ASSISTANT_PROFILE_REQUEST,
    ASSISTANT_PROFILE_SUCCESS,
    ASSISTANT_PROFILE_FAIL,
    ASSISTANT_PATIENTS_REQUEST,
    ASSISTANT_PATIENTS_SUCCESS,
    ASSISTANT_PATIENTS_FAIL,
    CLEAR_ERRORS,
} from '../constants/assistantConstants';

import { 
    TIME_REPORT_REQUEST,
    TIME_REPORT_SUCCESS,
    TIME_REPORT_FAIL
} from '../constants/HRConstants';

import { 
    ALL_PATIENTS_REQUEST, 
    ALL_PATIENTS_SUCCESS, 
    ALL_PATIENTS_FAIL,
    GET_DOCTOR_TELEMETARY_REPORT_REQUEST,
    GET_DOCTOR_TELEMETARY_REPORT_SUCCESS,
    GET_DOCTOR_TELEMETARY_REPORT_FAIL,
    PATIENT_PROFILE_REQUEST,
    PATIENT_PROFILE_SUCCESS,
    PATIENT_PROFILE_FAIL,
    GET_PATIENT_DEVICE_DATA_REQUEST,
    GET_PATIENT_DEVICE_DATA_SUCCESS,
    GET_PATIENT_DEVICE_DATA_FAIL,
    GET_PATIENT_REMAINING_READINGS,
    RPM_CRITICAL_DATA_SUCCESS
} from '../constants/adminConstants';

import { 
    SHOW_ALERT_MESSAGE,
    HIDE_ALERT_MESSAGE,
    FETCH_ERROR
} from '../constants/Common';


export const getAssistants = () => async (dispatch) => {
    try {
        dispatch({ type: REQUEST_ASSISTANTS })

        const data = await axios.get(`${Prod01}/assistant/get`);

        dispatch({
            type: REQUEST_ASSISTANTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: REQUEST_ASSISTANTS_FAIL,
            payload: error
        })
    }
}


// Get doctor profile => admin
export const getAssistantProfile = (id) => async(dispatch) => {
    try {
        dispatch({ 
            type: ASSISTANT_PROFILE_REQUEST 
        })

        const { data } = await axios.post(`${Prod01}/assistant/get/${id}`);
        
        dispatch({
            loading: false,
            type: ASSISTANT_PROFILE_SUCCESS,
            payload: data 
        })
        
    } catch (error) {
        dispatch({
            type: ASSISTANT_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}




// Add Patient
export const addAssistant = (values) => async(dispatch) => {
    try {

      await axios.post(`${Prod01}/assistant/add`, values );
    
        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "New Assistant Added"
            });
        
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error?.response?.data?.message
          })
    }
}


export const getPatients = (resPerPage, currentPage, drIds) => async(dispatch) => {
  
    try {
        dispatch({
            type: ASSISTANT_PATIENTS_REQUEST,
        })

        const { data } = await axios.post(`${Prod01}/assistant/patients/${resPerPage}/${currentPage}`, {
            drIds
        },{
            headers: {
            'Content-Type': 'application/json',
          }
        });
        
        dispatch({
            type: ASSISTANT_PATIENTS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ASSISTANT_PATIENTS_FAIL,
            payload: error.response.data.message
        })
    }
}


// Search Patient By Name
export const searchAssistantPatient = (searchBy, keyword, drIds) => async(dispatch) => {
    try {
        dispatch({
            type: ASSISTANT_PATIENTS_REQUEST,
        })

        const { data } = await axios.post(`${Prod01}/assistant/patient/search`,{
            key: searchBy,
            value:keyword,
            drIds: drIds
        }, );
        
        dispatch({
            type: ASSISTANT_PATIENTS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ASSISTANT_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getCCMPatients = (resPerPage, currentPage, drIds) => async(dispatch) => {
  
    try {
        dispatch({
            type: ASSISTANT_PATIENTS_REQUEST,
        })

    
        const { data } = await axios.post(`${Prod01}/assistant/ccm/list/${resPerPage}/${currentPage}`, {
            drIds
        },{
            headers: {
            'Content-Type': 'application/json',
          }
        });
        
        dispatch({
            type: ASSISTANT_PATIENTS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ASSISTANT_PATIENTS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getPatientTelemetaryReport = (patientId, startDate, endDate) => async(dispatch) => {
    
    try {
       dispatch({ 
           type: GET_DOCTOR_TELEMETARY_REPORT_REQUEST
       });
       
       const { data } = await axios.post(`${Prod01}/assistant/filterpatienthealthData/200/1`, {
        patientId: patientId,
        startDate: startDate,
        endDate: endDate,
        });
   
       dispatch({
           type: GET_DOCTOR_TELEMETARY_REPORT_SUCCESS,
           payload: data.healthData
       })    
   
    } catch (error) {
       dispatch({
           type: GET_DOCTOR_TELEMETARY_REPORT_FAIL,
           payload: error.message
       })
    }   
}

export const getPatientTelemetryData = (patientId, recordsPerPage, currentPage, sort) => async(dispatch) => {
    
    try {
       dispatch({ 
           type: GET_PATIENT_DEVICE_DATA_REQUEST
       });
       
       const { data } = await axios.post(`${Prod01}/assistant/filterpatienthealthData/${recordsPerPage}/${currentPage}`, {
               patientId: patientId,
               createdAt: sort
           });
   
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_SUCCESS,
           payload: data.healthData,
           count: data.Count
       })    
   
    } catch (error) {
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_FAIL,
           payload: error.message
       })
    }   
}

// Get patient profile => admin
export const patientProfile = (id) => async(dispatch) => {
    try {
        dispatch({ 
            type: PATIENT_PROFILE_REQUEST 
        })

        const { data }= await axios.get(`${Prod01}/assistant/patientprofile/${id}`, );
        
        dispatch({
            type: PATIENT_PROFILE_SUCCESS,
            loading: false,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: PATIENT_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}


// Time Spent on Patient
export const timeSpentOnPatient = (patientId, asstId, startDate, endDate, startTime, endTime, isCCM, interactiveMinutes, minutes, note, fileName, eType) => async (dispatch) => {
    let data = {};
    try {
        if(isCCM == false){

            let formData = new FormData();
            formData.append('file', fileName);

            if (fileName.name) {
                fetch(`${Prod01}/general/uploadfile`, {
                    method: 'POST',
                    body: formData,
                }
                )
            }

             data = await axios.post(`${Prod01}/assistant/addtimeforpatient/${asstId}`, {
                assigned_patient_id: patientId,
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
                interactiveMinutes: interactiveMinutes,
                timeSpentInMinutes: minutes,
                conclusion: note,
                fileName:fileName.name,
                encounterType: eType
            });
        } else {

            let formData = new FormData();
            formData.append('file', fileName);

            if (fileName.name) {
                fetch(`${Prod01}/general/uploadfile`, {
                    method: 'POST',
                    body: formData,
                })
            }

            data = await axios.post(`${Prod01}/assistant/addtimeforpatient/${asstId}`, {
                assigned_patient_id: patientId,
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
                isCCM:isCCM,
                interactiveMinutes: interactiveMinutes,
                timeSpentInMinutes: minutes,
                conclusion: note,
                fileName: fileName.name,
                encounterType: eType
            });
        } 
        
        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Time Added Successfully"
            }); 
    } 
   
    catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
          })
    }
}

export const getTimeReportByHR = (assistantId, startDate, endDate) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/assistant/listtargets&totaltimeOfAssistant`, {
            assistantId: assistantId,
            startDate: startDate,
            endDate: endDate
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getRPMMinutesReportOfAssistant = (assistantId, month, year, reportCategory, endMonth) => async (dispatch) => {
    if(reportCategory === 'RPM'){
        try {
            dispatch({
                type: TIME_REPORT_REQUEST
            });
    
            const { data } = await axios.post(`${Prod01}/assistant/listtargets&totaltimeOfAssistant`, {
                assistantId: assistantId,
                month: month,
                year: year
            });
    
            dispatch({
                type: TIME_REPORT_SUCCESS,
                payload: data,
            });
    
        } catch (error) {
            dispatch({
                type: TIME_REPORT_FAIL,
                payload: error.message
            })
        }
    } else {
        try {
            dispatch({
                type: TIME_REPORT_REQUEST
            });
    
            const { data } = await axios.post(`${Prod01}/assistant/listtargets&totaltimeOfAssistant`, {
                assistantId: assistantId,
                month: month,
                year: year,
                endMonth: endMonth,
                isCCM: true
            });
    
            dispatch({
                type: TIME_REPORT_SUCCESS,
                payload: data,
            });
    
        } catch (error) {
            dispatch({
                type: TIME_REPORT_FAIL,
                payload: error.message
            })
        }
    }
}

export const getRPMMinutesReportOfAssistantPatientProfile = (patientId, month, year) => async (dispatch) => {
        try {
            dispatch({
                type: TIME_REPORT_REQUEST
            });
    
            const { data } = await axios.post(`${Prod01}/assistant/listtargets&totaltimeOfAssistant`, {
                patientId: patientId,
                month: month,
                year: year,
            });
    
            dispatch({
                type: TIME_REPORT_SUCCESS,
                payload: data,
            });
    
        } catch (error) {
            dispatch({
                type: TIME_REPORT_FAIL,
                payload: error.message
            })
        }
    }

    
export const searchCCMPatient = (searchBy, keyword, drIds) => async(dispatch) => {
    try {
        dispatch({
            type: ASSISTANT_PATIENTS_REQUEST,
        })

        const { data } = await axios.post(`${Prod01}/assistant/patient/ccm/search`,{
            key: searchBy,
            value:keyword,
            drIds: drIds
        }, );
        
        dispatch({
            type: ASSISTANT_PATIENTS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ASSISTANT_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAssistantTimeReport = (drId, month, year, category, startMonth, endMonth) => async (dispatch) => { 
   if(category === 'RPM') {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/assistant/assistantTimeReportRPM&CCM`, {
            drId,
            month: month,
            year: year
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }    
   } else if(category === 'CCM') {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/assistant/assistantTimeReportRPM&CCM`, {
            drId: drId,
            month: startMonth,
            endMonth: endMonth,
            year: year,
            isCCM: true
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
     } 
   }
}

// Get Assistant Stats 
export const getAssistantStats = (drIds) => async(dispatch) => {
    try {
        const { data } = await axios.post(`${Prod01}/assistant/stats`, {
            drIds
        },{
            headers: {
            'Content-Type': 'application/json',
          }
        });

        dispatch({
            type: ASSISTANT_STATS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ASSISTANT_STATS_FAIL,
            payload: error.message
        })
    }
}

// Patients with critical data
export const getPatientsWithCriticalDataAssistant = (pagination, filterReadings, drIds) => async(dispatch) => {
    
    try {
       const {data} = await axios.post(`${Prod01}/assistant/getCriticalPts/${pagination}`, {
        drIds,
        readingType: filterReadings
    },{
        headers: {
        'Content-Type': 'application/json',
      }
    });

        dispatch({
            type: RPM_CRITICAL_DATA_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        
    }
}


export const changePassword = (asstId, oldPassword, newPassword) => async(dispatch) => {
    try {
        
    await axios.put(`${Prod01}/assistant/changePassword/${asstId}`, {
        oldPassword: oldPassword,
        password: newPassword
    });

    dispatch({
        type: SHOW_ALERT_MESSAGE,
        payload: "Password Updated"
    }); 

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
        })
    }
}


// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    })
}

