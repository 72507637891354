import {useState, useEffect} from 'react';
import Sidebar from '../../components/Assistant/Sidebar';
import TopBar from '../../components/Assistant/Topbar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import {Form, InputGroup, ProgressBar } from 'react-bootstrap';

import { 
    getPatients,  
    searchAssistantPatient
} from '../../actions/assistantActions';

import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {Badge } from 'react-bootstrap';
import moment from 'moment';
import Pagination from 'react-js-pagination';


const RPMPatients = () => {

    const dispatch = useDispatch();
    let compliantStatus; // variable to store compliant status of patient

    const alert = useAlert();
    const { loading, error, patients} = useSelector(state => state.asstPatients);
    const [keyword, setKeyword] = useState('');
    const [searchBy ,setSearchBy] = useState('emrId');
    const [isSearch, setIsSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [resPerPage, setResPerPage] = useState(20);

    let drIds = localStorage.getItem('myArray');
    const { RPM_Patients } = useSelector(state => state.asstStats);

    useEffect(() =>{

        if(error){
            alert.error(error);
        }

        dispatch(getPatients(resPerPage, currentPage, drIds))

    }, [error, currentPage]);
    

    function setCurrentPageNumber(pageNumber) {
        setCurrentPage(pageNumber);
    }

    const searchhandler = () => {
        dispatch(searchAssistantPatient(searchBy, keyword, drIds));
    }  

    const refreshHandler = () => {
        dispatch(getPatients(resPerPage, currentPage, drIds));
        setKeyword('');
    }

    function calculateCompliancyStatus(date) {  
        var m = moment(date);
        var today = moment().startOf('day');
        var days = moment.duration(today - m).asDays();

        switch(true){
            case (days >= 0 && days < 3):
                compliantStatus = 'compliant'
                break;

            case (days >= 3 && days <= 7):
                compliantStatus = "mostLikelyCompliant"
                break;

            case (days >= 8 && days <= 20):
                compliantStatus = "notLikelyCompliant"
                break;

            case (days >= 8 && days <= 31):
                compliantStatus = "nonCompliant"
                break;
            
            default:
                compliantStatus = 'nonCompliant'
                break;
        }
    }


    return (
        <>
                <MetaData title="RPM Patients"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                <br/>
                {loading ? <Loader /> : (
                <>   
                {/*  patients List Filter Section */}
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                        
                        <div className="row-display">
                            {/* <div className="col-md-8"> */}
                                <h5 className='title'>
                                    <i className="bx bx-user"></i> <strong>&nbsp;RPM Patients </strong>
                                </h5>
                            {/* </div> */}
                            <div>
                            <Link to="/clinical_staff/dashboard" className="btn btn-secondary">
                                <i className='bx bx-arrow-back' ></i></Link> &nbsp;
                                <Link to="/assistant/addpatient" className="edit-pill">
                                    <i className='bx bxs-user'></i> &nbsp;Add New Patient
                                </Link> &nbsp;
                                <button className="btn btn-secondary" onClick={refreshHandler}>
                                    <i className='bx bx-refresh'></i>
                                </button> &nbsp;
                            </div>
                        </div>
                        <hr />

                        <div>
                                <div className="col-md-5">
                                    <div className="row-display">
                                    
                                        <select 
                                            className="form-control select-input-type" 
                                            value={searchBy}
                                            onChange={e => setSearchBy(e.target.value)}
                                            >
                                                <option value="undefined">Filter Patient </option>
                                                <option value="emrId">EHR-ID</option>
                                                <option value="firstname">Firstname</option>
                                                <option value="lastname">Lastname</option>
                                                <option value="email">email</option>
                                                <option value="phone1">phone1</option>
                                                <option value="initialsetup">Initial Month</option>
                                                <option value="diseases">Diseases</option>
                                                <option value="insurancecompany">Ins. Company</option>
                                                <option value="address">Address</option>
                                                <option value="state">State</option>
                                                <option value="city">City</option>
                                                <option value="zipCode">Zip Code</option>
                                        </select>    
                                            &nbsp;
                                            <InputGroup>
                                                    <Form.Control
                                                        placeholder="Search here ..."
                                                        aria-label="Search here ..."
                                                        className="form-control"
                                                        value={keyword}
                                                        onChange={e => setKeyword(e.target.value) }
                                                    />
                                                    &nbsp;<button className="edit-pill m-2" 
                                                        id="button-addon2" onClick={searchhandler}>
                                                        <i className='bx bx-search-alt'></i>
                                                    </button>
                                            </InputGroup>
                                            </div>
                                        </div>

                                <div className="col-md-7">
                                </div>
                            </div>
                        </div>  


                    {/* Patient List Card */}
                    <div className="col-md-12">
                        {patients && <>
                            <table className="styled-table">
                            <thead align="center">
                                <tr>
                                <th>Name & EHR-ID</th>
                                <th>DOB </th>
                                <th>Device Status</th>
                                <th>Assigned Devices</th>
                                <th>Clinician</th>
                                <th>Last Reading & Compl. Status</th>
                                <th>Program Enrolled</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                            {patients && patients.length > 0 ? <> 
                                {patients && patients.map((patient, index) => (
                                <tr key={index}>  
                                <td className='text-center'>
                                    {patient?.block === false ? <Link className="link" 
                                        to={{ pathname: "/clinical_staff/patient", state: {patientid: patient?._id, batterySignalStatus: patient?.batterySignals?.sig}}}>
                                        {patient?.lastname}, {patient?.firstname}
                                    </Link> : <p className='text-muted'>
                                        {patient?.lastname}, {patient?.firstname} 
                                    </p>}
                                    <p>{patient?.emrId}</p>
                                </td>
                                
                                <td> {moment(patient?.DOB).format("MM/DD/YYYY")} <p>
                                <Badge pill bg="info text-white" style={{fontWeight: 500, 
                                        textTransform: 'uppercase', 
                                        padding: '3px',
                                        letterSpacing: '1px'
                                    }} key={index}>{patient?.gender}</Badge></p></td> 
                                
                                <td>{patient?.batterySignals?.sig >= 0 && patient?.batterySignals?.sig <= 10 ? 
                                        <text style={{color: 'red', fontWeight: 'bold'}}>Weak</text> : patient?.batterySignals?.sig > 10 && patient?.batterySignals?.sig <= 20 ? 
                                        <text style={{color: 'orange', fontWeight: 'bold'}}>Medium</text> : patient?.batterySignals?.sig > 20 ? <text style={{color: 'green', fontWeight: 'bold'}}>Strong</text> : '--'} 

                                    {patient?.batterySignals?.sig ? <ProgressBar animated variant="success" now={patient?.batterySignals?.bat} label={`${patient?.batterySignals?.bat}%`} /> : '--'}
                                </td>

                                <td className="text-center">
                                <p>{patient?.assigned_devices.map((device, index) => (
                                    <Badge bg="info text-white" style={{
                                        fontWeight: 500, 
                                        textTransform: 'uppercase', 
                                        padding: '5px',
                                        letterSpacing: '2px',
                                        margin: '2px'
                                    }} key={index}>{device?.deviceObjectId?.deviceType}</Badge>
                                ))}</p> 
                                </td> 
                                    
                                {patient?.assigned_doctor_id ? <>
                                <td><b>Dr. {patient?.assigned_doctor_id?.lastname}, {patient?.assigned_doctor_id?.firstname}</b></td>
                                </> : <>
                                <td>---</td>
                                </>}

                                {calculateCompliancyStatus(moment(patient?.lastReading))}    
                        
                                <td>
                                <span style={{fontWeight: 'bold'}}>{patient?.lastReading ? moment(patient?.lastReading).format("MM/DD/YYYY") : '--'}</span>
                                    <p>{patient?.lastReading ? 
                                        compliantStatus === 'compliant' ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                            Compliant</p> : compliantStatus === 'mostLikelyCompliant' ? <p style={{color: '#F95800', fontWeight: 'bold'}}>
                                                Most Likely Compliant</p> : compliantStatus === 'notLikelyCompliant' ? <p style={{color: 'dodgerblue', fontWeight: 'bold'}}>
                                                    Not Likely Compliant</p> : compliantStatus === 'nonCompliant' ? <p style={{color: 'red', fontWeight: 'bold'}}>
                                                    Non Compliant</p> : null : '--'}</p>
                                </td>

                                <td><b>{patient?.patientType === 'Both' ? 'RPM & CCM' : patient?.patientType}</b></td>
                                
                            </tr>    
                            ))}
                            </> : <div>
                                <small className="not-found-text">Patients Not Found</small>
                            </div> }
                            
                            </tbody>
                            
                            </table>
                            <small style={{color: 'gray'}}>Showing {resPerPage} records per page</small> 

                            {/* Pagination */}
                            {!isSearch && resPerPage <= RPM_Patients && (
                                    <div className="d-flex justify-content-center mt-5"> 
                                    <Pagination activePage={currentPage} 
                                    itemsCountPerPage={resPerPage} 
                                    totalItemsCount = {RPM_Patients}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                            </div>
                                )}    
  
                        </>}                      
                        </div>
                    </div>
                </>
            )}
            </section>
        </>
    )
}

export default RPMPatients
